import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as SecureLS from "secure-ls";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, Spin, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./style.css";
import "../list/teachers.css";

const { Search } = Input;
var ls = new SecureLS({ encodingType: "aes" });
const token = ls.get("isLogin");
const loginDetails = ls.get("isLogin");

const StudentListNew = (props) => {
  const { studentsList } = props;
  const { confirm } = Modal;
  let history = useNavigate();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [studentListCount, setStudentListCount] = useState(
    studentsList?.active?.data || []
  );
  const [activeMenu, setActiveMenu] = useState("active");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    if (studentsList) {
      const currentList = studentsList?.[activeMenu]?.data || [];
      setStudentListCount(currentList);
      setFilteredStudents(currentList);
    } else {
      setStudentListCount([]); // Default to empty array if studentsList is null or undefined
      setFilteredStudents([]);
    }
  }, [activeMenu, studentsList]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filtered = studentListCount.filter(
      (student) =>
        student.emailId.toLowerCase().includes(value.toLowerCase()) ||
        student.mobileNumber.includes(value)
    );
    setFilteredStudents(filtered);
  };

  const render = () => {
    return (
      <div className="payment-container">
        {isLoading ? (
          <div className="load">
            <Spin tip="Loading..." style={{ textAlign: "center" }} />
          </div>
        ) : (
          <div className="d-flex flex-wrap flex-stack">
            <div
              className="d-flex flex-column flex-grow-1 pe-8"
              style={{ width: "100%" }}
            >
              <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Search
              placeholder="Search by Email or Phone Number"
              allowClear
              enterButton="Search"
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              onSearch={handleSearch}
              style={{ width: "400px" }}
            />
          </div>
              <div className="d-flex flex-wrap boxSize">
                {[
                  "ACTIVE",
                  "PAID",
                  "ENGAGED",
                  "DISENGAGED",
                  "EXPIRED",
                ].map((menu) => (
                  <div
                    key={menu}
                    className={`border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox ${
                      activeMenu === menu.toLowerCase() ? "Button" : ""
                    }`}
                    onClick={() => handleMenuClick(menu.toLowerCase())}
                  >
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {studentsList?.[menu.toLowerCase()]?.count || "-"}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 ButtonState">
                      <b>{menu}</b>
                    </div>
                  </div>
                ))}
              </div>
              {filteredStudents.length === 0 ? (
                <div className="singleCard">No Data Available</div>
              ) : (
                <div
                  className="TeacherStudent-card"
                  style={{ width: "1000px" }}
                >
                  <div
                    className="DashboardScreen-container"
                    style={{
                      maxHeight: "70vh",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <table className="Dashboard-card-screen student-grid">
                      <thead>
                        <tr>
                          <th className="table-cell">S.No</th>
                          <th className="table-cell">Name</th>
                          <th className="table-cell">Email</th>
                          <th className="table-cell">Mobile Number</th>
                          <th className="table-cell">Last Class Taken</th>
                          <th className="table-cell">Enrollment Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredStudents.map((student, index) => (
                          <tr key={index}>
                            <td className="table-cell">{index + 1}</td>
                            <td className="table-cell">
                              <Link
                                to={{
                                  pathname: `/student-view`,
                                  search: `?id=${student.studentId}&credid=${student.studentCredentialId}`,
                                }}
                              >
                                {student.studentName}
                              </Link>
                            </td>
                            <td className="table-cell">{student.emailId}</td>
                            <td className="table-cell">{student.mobileNumber}</td>
                            <td
                              className="table-cell"
                              style={{ textAlign: "center" }}
                            >
                              {student.lastClassTaken
                                ? moment(student.lastClassTaken).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"}
                            </td>
                            <td className="table-cell">
                              {moment(student.enrollmentDate).format(
                                "DD/MM/YYYY HH:mm"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return render();
};

const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});


export default connect(mapStateToProps)(StudentListNew);