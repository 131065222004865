import React, { useState, useEffect } from 'react';
import appEnvirontVariables from "../../../src/core_units/config/env-variables";
import { Modal, Spin, Button, List, message } from "antd";
import * as SecureLS from "secure-ls";

const AuditTrail = () => {
  const [auditData, setAuditData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize SecureLS and retrieve token
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");

  const fetchAuditTrail = async () => {
    try {
      const response = await fetch(`${appEnvirontVariables.API.BASE_URL}/api/audittrail/auditTrail`, {
        method: "GET",
        headers: {
          Authorization: token["authorization"],
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAuditData(data); // Assuming `data` is an array of audit trail objects
    } catch (err) {
      setError(err.message);
      console.error("Error fetching audit trail:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAuditTrail();
  }, []);

  return (
<div style={{ height: "570px", overflow: "hidden",   border: "1px solid #ddd", }}>
  <div style={{ overflowY: "auto", maxHeight: "100%" }}>
    {isLoading ? (
     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
     <Spin size="large" />
   </div>
    ) : error ? (
      <p style={{ color: "red" }}>Error: {error}</p>
    ) : (
      <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              Date & Time
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              Action By
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              Action
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              User Impacted
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              Details of Change
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                backgroundColor: "#f0f0f0",
                position: "sticky",
                top: 0,
                zIndex: 1,
                textAlign: "center",
              }}
            >
              IP Address
            </th>
          </tr>
        </thead>
        <tbody>
          {auditData.length > 0 ? (
            auditData.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.auditDate || "-"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.actionBy || "-"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.audionPerformed || "-"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.userImpacted || "-"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.detailsChanged || "-"}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {item.ipAddress || "-"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center", padding: "8px" }}>
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )}
  </div>
</div>



  );
};

export default AuditTrail;
