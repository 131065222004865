import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Card, Spin } from "antd";
import jsPDF from "jspdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import html2canvas from "html2canvas";
import * as SecureLS from "secure-ls";
import axios from "axios";
import './arm.css';
import { ExportOutlined } from "@ant-design/icons";



import artiumGoldLogo from '../../../artium-gold-logo.png';
import artiumLogo from '../../../artium-logo.png';
import artiumPlatinumLogo from '../../../platinum-logo.png';
import appEnvirontVariables from "../../../core_units/config/env-variables";

import { Buffer } from "buffer";


const { Search } = Input;
var ls = new SecureLS({ encodingType: "aes" });
const token = ls.get("isLogin");
const loginDetails = ls.get("isLogin");


const Arm = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("logindetails", loginDetails)
const {  selectedCourse} = props;
console.log("studentCourse",  selectedCourse)
const {profile} = props;
console.log("profile2",  profile.userId)

  // const [userInput, setUserInput] = useState({
  //   paragraph1: "",
  //   paragraph2: "",
  //   paragraph3: "",
  // });
  const [isLoading, setIsLoading] = useState(false); 

  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");
  const [para1,setPara1] = useState("");
  const [para2,setPara2] = useState("");
  const [para3,setPara3] = useState("");
  const [reports, setReports] = useState("")

  const [classNumber, setClassNumber] = useState(1);


  useEffect(() => {
    // Fetch reports when the component mounts
    getReports();
  }, [selectedCourse.courseId]);
  const handleInputChange1 = (e) => {
    setPara1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    setPara2(e.target.value);
  };

  const handleInputChange3 = (e) => {
    setPara3(e.target.value);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const margin = 10;
    let y = margin;
    let studentName = profile.firstName;
    let studentCourse = selectedCourse.courseName;
    let userType = profile.membershipType;
    let reviewer = loginDetails.studentName;
    const currentDate = new Date().toLocaleDateString();

   
    const templateGold = `
    <div style="font-family: 'Roboto', Arial, sans-serif; margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; flex-direction: column;">
  <div style="display: flex; justify-content: center; height: 500px; align-items: center; background: linear-gradient(#000000, #2A2A2A); width: 100%; ">
    <div style="background: linear-gradient(#000000, #2A2A2A); color: #fff; height: 90%; width: 90%; padding: 30px; border: 2px solid #f5b342;  text-align: center; position: relative; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); margin: 20px;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <img src="${artiumLogo}" alt="Artium Academy Logo">
        <div style="width: 120px; height: 120px; border-radius: 50%;border: 1px; display: flex; justify-content: center; align-items: center; text-align: center;">
          <img style="width: 100%; height: 100%;" src="${artiumGoldLogo}" alt="Artium Academy Logo" >
        </div>
      </div>
      <div style="margin-top: 20px;">
        <h1 style="margin: 30px 0 10px; font-size: 28px; font-weight: 700;">Academic Review Report</h1>
        <div style="display: flex; justify-content: space-between; margin-top: 20px; border-top: 1px solid #666; padding-top: 20px;">
          <div style="text-align: left;">
            <h2 style="font-size: 18px; margin-bottom: 10px; color: #f5b342;">Student Details</h2>
            <p style="margin: 5px 0; font-size: 16px;">${studentName}</p>
            <p style="margin: 5px 0; font-size: 16px;">${studentCourse}</p>
          </div>
          <div style="text-align: left;">
            <p style="margin: 5px 0; font-size: 16px;">Reviewed by:</p>
            <h2 style="font-size: 18px; margin-bottom: 10px; color: #f5b342;">${reviewer}</h2>
            <p style="margin: 5px 0; font-size: 16px;">${currentDate}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`;

const templatePlatinum = `
    <div style="font-family: 'Roboto', Arial, sans-serif; margin: 0; padding: 0; display: flex; justify-content: center; align-items: center; flex-direction: column;">
  <div style="display: flex; justify-content: center; height: 500px; align-items: center; background: linear-gradient(#23142C, #410D59); width: 100%; ">
    <div style="background: linear-gradient(#23142C, #410D59); color: #fff; height: 90%; width: 90%; padding: 30px; border: 2px solid white;  text-align: center; position: relative; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); margin: 20px;">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <img src="${artiumLogo}" alt="Artium Academy Logo" style="max-width: 120px;">
        <div style="width: 120px; height: 120px; border-radius: 50%; border: 1px; display: flex; justify-content: center; align-items: center; text-align: center;">
          <img style="width: 100%; height: 100%;" src="${artiumPlatinumLogo}" alt="Artium Academy Logo" >
        </div>
      </div>
      <div style="margin-top: 20px;">
        <h1 style="margin: 30px 0 10px; font-size: 28px; font-weight: 700;">Academic Review Report</h1>
        <div style="display: flex; justify-content: space-between; margin-top: 20px; border-top: 1px solid #666; padding-top: 20px;">
          <div style="text-align: left;">
            <h2 style="font-size: 18px; margin-bottom: 10px;">Student Details</h2>
            <p style="margin: 5px 0; font-size: 16px;">${studentName}</p>
            <p style="margin: 5px 0; font-size: 16px;">${studentCourse}</p>
          </div>
          <div style="text-align: left;">
            <p style="margin: 5px 0; font-size: 16px;">Reviewed by:</p>
            <h2 style="font-size: 18px; margin-bottom: 10px;">${reviewer}</h2>
            <p style="margin: 5px 0; font-size: 16px;">${currentDate}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`;
  
    
    // Create and render the template
    const container = document.createElement("div");
    container.innerHTML = (userType?.toLowerCase() === "goldmembership" ? templateGold : templatePlatinum);
    document.body.appendChild(container);
    const canvas = await html2canvas(container);
    const dynamicImage = canvas.toDataURL("image/png");
    document.body.removeChild(container);

    doc.addImage(dynamicImage, "PNG", 0, 0, 210, 90);
    y += 90;

    const paragraphs = [
        { title: "Progress Review", content: para1 },
        { title: "Behavioural Review", content: para2 },
        { title: "Any Other Feedback", content: para3 },
    ];

    const lineHeight = 10;
    const maxWidth = 180;

    paragraphs.forEach(({ title, content }) => {
        if (y + lineHeight > 290) {
            doc.addPage();
            y = margin;
        }
        doc.setFont("helvetica", "bold");
        doc.text(title, margin, y);
        y += lineHeight;

        doc.setFont("helvetica", "normal");
        const splitContent = doc.splitTextToSize(content, maxWidth);
        splitContent.forEach((line) => {
            if (y + lineHeight > 290) {
                doc.addPage();
                y = margin;
            }
            doc.text(line, margin, y);
            y += lineHeight;
        });
    });

    const pdfBuffer = doc.output("arraybuffer");

    try {
      const fileName = `${selectedCourse.courseId}_${classNumber}`;

        const preSignedUrl = await axios.get(
            `${appEnvirontVariables.API.REFERRAL_STUDENT_URL}/api/create-report/uploadAePreSignedUrl/${profile.userId}?fileName=${fileName}.pdf`,
            {
                headers: {
                    Authorization: token["authorization"],
                    "Content-Type": "application/pdf",
                },
            }
        );

        console.log("PreSigned URL:", preSignedUrl);

        await axios.put(preSignedUrl.data.data, Buffer.from(pdfBuffer), {
            headers: {
                "Content-Type": "application/pdf",
            },
        });
        
        console.log("PDF uploaded successfully");

        const payload = {
            userType: profile.membershipType,
            studentName: profile.firstName,
            studentCourse: selectedCourse.courseName,
            reviewer: loginDetails.studentName,
            courseId: selectedCourse.courseId,
            class_Number: classNumber.toString(),
            progress: para1,
            behavioural: para2,
            other: para3,
        };

        const response = await fetch(
            `${appEnvirontVariables.API.REFERRAL_STUDENT_URL}/api/create-report/${profile.userId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token["authorization"],
                },
                body: JSON.stringify(payload),
            }
        );

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }


        console.log("Report submitted successfully");
    } catch (error) {
        console.error("Error during PDF generation or upload:", error);
    }
};


  const showModal = () => {
    setIsModalOpen(true);
  };


  const getReports = async (communication) => {
    const url = `${appEnvirontVariables.API.REFERRAL_STUDENT_URL}/api/create-report/getReport/${profile.userId}?courseId=${selectedCourse.courseId}`;

    try {
      const response = await axios.get(url, {
        headers: { Authorization: token["authorization"] },
      });
      console.log("API Response:", response.data.data);
      const reportData = response.data.data || [];
      if(communication && reportData.length){
        await axios.post(
          `${appEnvirontVariables.API.BASE_URL}/api/users/academic/review/meeting`,
          {
            userName: profile.firstName,
            mobileNumber: profile.countryCode + profile.mobileNumber,
            email: profile.emailId,
            Url: reportData[reportData.length - 1].pdfUrl
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
      }
      setReports(reportData);
      setClassNumber(reportData.length + 1); 
      console.log("reports", reports);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  


  const handleOk = async () => {
    if (!para1 || !para2 || !para3) {
        alert("Please fill in all the fields.");
        return;
    }
    setIsLoading(true);
 await generatePDF();
 getReports(true);
 clearInputs();
   setIsModalOpen(false);

   setIsLoading(false);


};


  const handleCancel = () => {
    setIsModalOpen(false);
    clearInputs();

  };

  const clearInputs = () => {
    setPara1("");
    setPara2("");
    setPara3("");
  };

  const openPdfModal = (pdfUrl) => {
    setCurrentPdfUrl(pdfUrl);
    setIsPdfModalOpen(true);
  };

  const closePdfModal = () => {
    setCurrentPdfUrl("");
    setIsPdfModalOpen(false);
  };

  return (
    <div>
  <div>
     
      </div>
      <Button type="primary" onClick={showModal}>
        Add New Reports
      </Button>
 
      <Modal
       style={{ maxHeight: "70vh", overflowY: "auto",}}
        title="Add New Report"
        visible={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ disabled: isLoading }} 
        cancelButtonProps={{ disabled: isLoading }} 

        onCancel={isLoading ? null : handleCancel} 
        okText= {isLoading ? 'Saving...' : 'Save'}
        cancelText="Cancel"
      >
        <div>
          <label>Progress Review:</label>
          <Input.TextArea  
            value={para1}
            onChange={handleInputChange1} rows={4} placeholder="Enter progress review here..." />
        </div>
        <div style={{ marginTop: "16px" }}>
          <label>Behavioural Review:</label>
          <Input.TextArea 
            value={para2} 
            onChange={handleInputChange2} rows={4} placeholder="Enter behavioural review here..." />
        </div>
        <div style={{ marginTop: "16px" }}>
          <label>Any Other Feedback:</label>
          <Input.TextArea rows={4}  
            value={para3}
            onChange={handleInputChange3} placeholder="Enter additional feedback here..." />
        </div>
      </Modal>
      <div className="report-container">
  {Array.isArray(reports) && reports.length > 0 ? (
    reports.map((report, index) => (
      <div className="report-card" key={index}>
      
         <div style={{display:"flex", justifyContent:"space-between"}}> <strong>Academic Review {report.class_Number}</strong> 
        
     
          <button
          className="report-button"
          onClick={() => openPdfModal(report.pdfUrl)}
        >
          View PDF
        </button></div>
        
         {report.createdAt ? new Date(report.createdAt).toLocaleDateString() : "-"}

        <p>
          <strong>Reviewed by</strong> {report.reviewer}
        </p>
        <p>
        {/* <strong>Date:</strong> {new Date(report.createdAt).toLocaleDateString()  } */}
 
        </p>
     
      </div>
    ))
  ) : (
    <p>No reports available.</p>
  )}
</div>


<Modal
  visible={isPdfModalOpen}
  title={
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  
      <a
        href={currentPdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#1890ff",
          textDecoration: "underline",
          fontSize: "14px",
        }}
      >
    <ExportOutlined style={{ fontSize: "18px", marginRight:"30px" }} />
      </a>
    </div>
  }
  onCancel={closePdfModal}
  footer={null}
  width="50%"
>
  {currentPdfUrl ? (
    <iframe
      src={`${currentPdfUrl}#toolbar=0&view=FitH`}
      title="PDF Viewer"
      width="100%"
      height="500px"
      style={{ border: "none" }}
    />
  ) : (
    <p>No PDF available</p>
  )}
</Modal>


    </div>

  );
};

export default Arm;
