import React, {useState, useEffect} from 'react';
import './styles.css';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { Button, Select, Table, Form, Input, InputNumber, notification, Spin } from 'antd';
import * as SecureLS from "secure-ls";
import _ from 'lodash';
import { useSearchParams, useLocation } from "react-router-dom";
import axios from 'axios';
import formatDate from '../../../core_units/utils/dayjs';

const Refund = (props) => {
    //const [courseList, setCourseList] = useState([]);
    const [subscriptionCourse, setSubscriptionCourse] = useState();
    const [configData, setConfigData] = useState([]);
    const [trasactionList, setTrasactionList] = useState([]);
    const [refundRequestList, setRefundRequestList] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState();
    const [loading, setLoading] = useState(false);
    var ls = new SecureLS({ encodingType: 'aes' });
    const token = ls.get('isLogin');
    const [searchParam] = useSearchParams();

    useEffect(async () => {
      init();
    },[])

    const dayCalculation = (e, subscription) => {
      const startDate = new Date(e.createdAt); // Replace with your start date
      const endDate = new Date(e.captureDate);   // Replace with your end date
      let expiredClassCount = Math.ceil((daysBetweenDates(startDate, endDate)) / 7);
      (e.courseList || []).forEach(z => {
        if(!z.isFree){  // value of this key (classesAvailableForRefund) is completed class count
          let completedClass = subscription.find(c => e.courseId == z.courseId)?.classesAvailableForRefund || 0;
          let refundClasses;
          if(expiredClassCount > completedClass){
            refundClasses = (+z.classesToPurchase < expiredClassCount) ? 0 : +z.classesToPurchase - expiredClassCount;
            expiredClassCount -= (+z.classesToPurchase - refundClasses);
          }else{
            refundClasses = +z.classesToPurchase - completedClass;
            expiredClassCount = 0;
          }
          //refundClasses = +z.classesToPurchase - (expiredClassCount > completedClass ? expiredClassCount : completedClass);
          //expiredClassCount -= (refundClasses > expiredClassCount) ? +z.classesToPurchase : expiredClassCount;
          z.eligibleRefundClasses = refundClasses > 0 ? refundClasses : 0;
          z.refundDiscout = 0;
          z.requestedRefundClasses = z.eligibleRefundClasses;
          z.refundableAmount = (z.requestedRefundClasses * z.unit_price);
          //e.totalClasses += +z.classesToPurchase;
        }else{
          z.eligibleRefundClasses = 0;
          z.requestedRefundClasses = z.eligibleRefundClasses;
          z.refundableAmount = 0;
        }
      })
    }

   const init = async () => {
    setLoading(true);
    const defaultExpiryDate = new Date();
    //defaultExpiryDate.setDate(defaultExpiryDate.getDate() + (props.batchData ? 2 : 7));
    let year = defaultExpiryDate.getFullYear();
    let month = String(defaultExpiryDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    let day = String(defaultExpiryDate.getDate()).padStart(2, '0');

    const defaultExpiryDateString = `${year}-${month}-${day}`;
      const result = await Promise.all([getAllPayments().catch(() => { return {} }),
        getExternalPayment().catch(() => { return {} }), getRefundList().catch(() => { return {} }), getSubcriptionCourse().catch(() => { return {} })
     ]);
      let refundRequestIds = [];
      if(result[2].data && result[2].data.data && result[2].data.data.Items.length){
        setRefundRequestList(result[2].data.data.Items);
        refundRequestIds = result[2].data.data.Items.map((v) => v.id )
      }
      setSubscriptionCourse(result[3].data || []);
      let courseList = []
      if(result[0].data || result[1].data){
        courseList = [...result[0].data.filter(e => (e.payment_status || "").indexOf("payment_success") > -1 && e.courseList && refundRequestIds.indexOf(e.id) < 0), ...result[1].data.filter(e => !e.isFreeClass && refundRequestIds.indexOf(e.id) < 0)];
        courseList.forEach(e => {
          if(e){
              e.captureDate = defaultExpiryDateString;
              dayCalculation(e, result[3].data || []);
              // const startDate = new Date(e.createdAt); // Replace with your start date
              // const endDate = new Date(e.captureDate);   // Replace with your end date
              // let expiredClassCount = Math.ceil((daysBetweenDates(startDate, endDate)) / 7);
              // (e.courseList || []).forEach(z => {
              //   if(!z.isFree){  // value of this key (classesAvailableForRefund) is completed class count
              //     let completedClass = result[3].data.find(c => e.courseId == z.courseId)?.classesAvailableForRefund || 0;
              //     //courseList.push(z);
              //     let refundClasses = +z.classesToPurchase - (expiredClassCount > completedClass ? expiredClassCount : completedClass);
              //     expiredClassCount -= (refundClasses > expiredClassCount) ? refundClasses : expiredClassCount;
              //     z.eligibleRefundClasses = refundClasses > 0 ? refundClasses : 0;
              //     z.refundDiscout = 0;
              //     z.requestedRefundClasses = z.eligibleRefundClasses;
              //     z.refundableAmount = (z.requestedRefundClasses * z.unit_price);
              //     //e.totalClasses += +z.classesToPurchase;
              //   }else{
              //     z.eligibleRefundClasses = 0;
              //     z.requestedRefundClasses = z.eligibleRefundClasses;
              //     z.refundableAmount = 0;
              //   }
              // })
          }
        })
 }
 
 setTrasactionList(courseList);
 setLoading(false);
    }

    function daysBetweenDates(date1, date2) {
      // Convert both dates to milliseconds since the Unix Epoch
      const time1 = date1.getTime();
      const time2 = date2.getTime();
  
      // Calculate the difference in milliseconds
      const timeDiff = Math.abs(time2 - time1);
  
      // Convert the difference back to days
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
      return daysDiff;
  }

    const getAllPayments = async () => {
        return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/order/paymentlink/${searchParam.get("id")}`,
        { headers: { "Authorization": token['authorization'] } });
    }
    const getRefundList = async () => {
      return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/refund-request/${searchParam.get("id")}`, {studentId:searchParam.get("id").toString()},
      { headers: { "Authorization": token['authorization'] } });
  }
    const getExternalPayment = async () => {
        return axios.get(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/external-payment/${searchParam.get("id")}`,
        { headers: { "Authorization": token['authorization'] } });
    }
    const getSubcriptionCourse = async () => {
        return axios.get(`${appEnvirontVariables.API.BASE_URL}/api/admin/refund/data?studentId=${searchParam.get("id")}`,
        { headers: { "Authorization": token['authorization'] } });
    }

    // const courseChange = async (event) => {
    //       let index = (event.target.value || event.currentTarget.value);
    //       if (index != "") {
    //         setSelectedCourse(courseList[index]);
    //       }
    // } 
    const handleChange = (index,idx,e) => {
      //const re = /^[0-9\b]+$/;
      //if (e.target.value === '' || re.test(e.target.value)) {
      //if (e.target.value === '') {
        const { name, value } = e.target;
        trasactionList[index]["courseList"][idx][name] = value;
        trasactionList[index]["courseList"][idx].refundableAmount = ((trasactionList[index]["courseList"][idx].requestedRefundClasses || 0 ) * trasactionList[index]["courseList"][idx].unit_price) - (trasactionList[index]["courseList"][idx].refundDiscout || 0);
        setTrasactionList([...[],...trasactionList]);
      //}
  
    };
    const handleReasonChange = (index,e) => {
      //const re = /^[0-9\b]+$/;
      //if (e.target.value === '' || re.test(e.target.value)) {
      //if (e.target.value === '') {
        const { name, value } = e.target;
        trasactionList[index][name] = value;
        setTrasactionList([...[],...trasactionList]);
      //}
  
    };
    const handleDateChange = (index,e) => {
        const { name, value } = e.target;
        trasactionList[index][name] = value.trim();
        dayCalculation(trasactionList[index], subscriptionCourse);
        setTrasactionList([...[],...trasactionList]);
    };
    const refundRequest = () => {
      if(selectedTransaction && selectedTransaction.courseList.filter(e => e.requestedRefundClasses).length){
        if(!selectedTransaction.reasonforrefund?.trim()){
          notification.error({
            message: "",
            description: "Please enter reason for refund.",
          });
          return;
        }
        setLoading(true);
        //delete selectedTransaction.invoice_result;
        delete selectedTransaction.razorpay_response;
        delete selectedTransaction.webhook_response;
        selectedTransaction.refund_status = "pending";
        selectedTransaction.courseList.forEach(e => {
          e.approvel_classes = e.requestedRefundClasses;
          e.approvel_amount = e.refundableAmount;
          e.approvel_discount = e.refundDiscout;
        });
        selectedTransaction.active = true;
        axios.post(`${appEnvirontVariables.API.PAYMENT_URL}/api/payment/refund-request`,
        selectedTransaction,
        { headers: { "Authorization": token['authorization'] } }).then((r) => {
          setLoading(false);
          if(r.data.success){
            init();
            notification.success({
              message: "",
              description: "Refund request added successfully",
            });
          }
        })
      }
    }
   
    return (
    <div>
      {loading ? (
              <div style={{display:"flex",justifyContent:"center"}}><Spin size="large" spinning={loading} style={{justifyContent:"center"}}/></div>
              ) : (
        <div>
      <h4>Transaction List</h4>
    
      {(trasactionList || []).map((v, index) => {
                    return <span style={{cursor:'pointer'}} onClick={() => {
                      setSelectedTransaction(v);
                    }}><input style={{position: "absolute",marginTop: "5px", marginLeft:"5px"}} className='' checked={v.id == selectedTransaction?.id} type='radio'></input>
                    <ul key={index} style={{listStyle: "none", boxShadow:"0px 0px 9px 0px #888888", background:(v.id == selectedTransaction?.id) ? "#E0F7FA":""}}>
                    <li><table>
                            <tr><td>Transaction Date</td>
                            <td>: <span>{formatDate(v.createdAt,true)}</span></td></tr>
                            <tr><td>Transaction Amount</td>
                                <td>: <span>{v.currency} {v.totalPrice}</span></td></tr>
                            </table></li>
                    <li>
                    <table className="table table-bordered table-hover">
                    <thead><tr>
                    {/* <th className="text-center">Free Course</th> */}
                    <th className="text-center">Course Name</th>
                    <th className="text-center">Price/Class (inc.Tax)</th>
                    <th className="text-center">Classes</th>
                    <th className="text-center">Discount</th>
                    <th className="text-center">Total Price</th>
                    <th className="text-center">Refundable Classes</th>
                    <th className="text-center">Refundable Amount ({v.currency})</th>
                    <th className="text-center">Discount on Refund({v.currency})</th>
                  </tr></thead>
                  <tbody>{(v.courseList || []).map((z, idx) => {
                      return <tr key={idx}>
                        {/* <td><input className='' readOnly disabled={!z.isFree} checked={z.isFree}  type='checkbox'></input></td> */}
                        <td>{z.courseName}{z.isFree ? <b style={{color:"green"}}>-Free Course</b> : ""}</td>
                        <td>{z.unit_price}</td>
                        <td>{z.classesToPurchase}</td>
                        <td>{z.discountedPrice}</td>
                        <td>{z.price}</td>
                        <td>
                          <input
                            type="text"
                            name="requestedRefundClasses"
                            disabled={z.isFree}
                            value={z.requestedRefundClasses}
                            onChange={(e) => handleChange(index,idx,e)}
                            className="form-control"
                          />
                        </td>
                        <td><span><input
                            type="text"
                            name="refundableAmount"
                            // disabled={z.isFree}
                            disabled={true}
                            value={z.refundableAmount}
                            onChange={(e) => handleChange(index,idx,e)}
                            className="form-control"
                          /></span></td>
                          <td><span><input
                            type="text"
                            name="refundDiscout"
                            disabled={z.isFree}
                            value={z.refundDiscout}
                            onChange={(e) => handleChange(index,idx,e)}
                            className="form-control"
                          /></span></td>
                      </tr>
                       })}
                {v.id == selectedTransaction?.id ? <tr><td>
                  Capture date of refund : <input className="form-control" style={{ width: '150px', textAlign: 'right', marginTop: '5px' }}
                type="date"
                id="capture-date"
                name="captureDate"
                value={v.captureDate}
                onChange={(e) => handleDateChange(index,e)}
              />
                  </td><td className='reasonRefund' colSpan={2}>*Reason for Refund :</td><td style={{borderLeft:"none"}} colSpan={4}>
                  <select
                    name="reasonforrefund"
                    value={v.reasonforrefund}
                    onChange={(e) => handleReasonChange(index, e)}
                    className="form-control"
                  >
                    <option value="">Select a reason</option>
                    <option value="Personal reasons">Personal reasons</option>
                    <option value="Academics Issues">Academics Issues</option>
                    <option value="Teacher Escalations">Teacher Escalations</option>
                    <option value="Previous teacher unavailable">Previous teacher unavailable</option>
                    <option value="Prefers Offline">Prefers Offline</option>
                    <option value="Medical emergency">Medical emergency</option>
                    <option value="Child is too small to cope up">Child is too small to cope up</option>
                    <option value="Operational Issue">Operational Issue</option>
                    <option value="Desired slots unavailable">Desired slots unavailable</option>
                    <option value="Technical Issues">Technical Issues</option>
                    <option value="Moved to Artium offline">Moved to Artium offline</option>
                  </select>
                  </td><td>
                         <button
                  onClick={() => {refundRequest()}}
                  className="btn btn-primary float-right"
                  disabled={loading}
                >
                  {loading ? "Loading ...":'Request Refund'}
                </button></td></tr> : ""}
                      </tbody></table></li>
                    </ul>
                    </span>
                  })}
      {refundRequestList.length ? <h4>Refund Request List</h4>: ""}
      {(refundRequestList || []).map((v, index) => {
                    return <span>
                    <ul key={index} style={{listStyle: "none", boxShadow:"0px 0px 9px 0px #888888", background:(v.id == selectedTransaction?.id) ? "#E0F7FA":""}}>
                    <li><table>
                            <tr><td>Transaction Date</td>
                                <td>: <span>{formatDate(v.createdAt, true)}</span></td></tr>
                            <tr><td>Transaction Amount</td>
                                <td>: <span>{v.currency} {v.totalPrice}</span></td></tr>
                            </table></li>
                    <li>
                    <table className="table table-bordered table-hover">
                    <thead><tr>
                    {/* <th className="text-center">Free Course</th> */}
                    <th className="text-center">Course Name</th>
                    <th className="text-center">Price/Class (inc.Tax)</th>
                    <th className="text-center">Classes</th>
                    <th className="text-center">Discount</th>
                    <th className="text-center">Total Price</th>
                    <th className="text-center">Refundable Classes</th>
                    <th className="text-center">Refundable Amount ({v.currency})</th>
                    <th className="text-center">Discount on Refund({v.currency})</th>
                  </tr></thead>
                  <tbody>{(v.courseList || []).map((z, idx) => {
                      return <tr key={idx}>
                        {/* <td><input className='' readOnly disabled={!z.isFree} checked={z.isFree}  type='checkbox'></input></td> */}
                        <td>{z.courseName}{z.isFree ? <b style={{color:"green"}}>-Free Course</b> : ""}</td>
                        <td>{z.unit_price}</td>
                        <td>{z.classesToPurchase}</td>
                        <td>{z.discountedPrice}</td>
                        <td>{z.price}</td>
                        <td>
                        {z.approvel_classes}
                        </td>
                        <td>{z.approvel_amount}</td>
                        <td>{z.approvel_discount}</td>
                      </tr>
                       })}
                       <tr><td className='reasonRefund'>*Reason for Refund :</td><td style={{borderLeft:"none", padding: "15px 5px"}}>
                       {v.reasonforrefund}
                  </td><td colSpan={5} style={{textAlign:"right"}}><b>Status</b></td><td><b style={{borderRadius:"5px",padding:"5px",background: v.refund_status == "pending"? "yellow":"green"}}>{v.refund_status.toUpperCase()}</b></td></tr>
                      </tbody></table></li>
                    </ul>
                    </span>
                  })}
          </div>
)}
        {/* <label className="form-label">Select Course <span></span></label> */}
        {/* <select className="form-control" style={{ width: '300px', appearance: 'auto' }}
                          dropdownClassName='course-list-dropdown'
                          placeholder='Select Course'
                          id="course-list"
                          onChange={courseChange}
                        >
                          <option value="">Select Course</option>
                          {courseList.length && courseList.map((course, index) => {
                            return <option value={index} >{course['courseName']}</option>
                          })}
                        </select> */}
    </div>)

}
export default Refund;