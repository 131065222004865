import loginPage from '../../containers/account/login/login';
import teachersPage from '../../containers/teachers/list/teachers';
import studentsPage from '../../containers/students/list/students';
import profilePage from '../../containers/admin/profile/index';
import teacherProfilePage from '../../containers/teachers/profile/profile';
import teacherCalendarPage from '../../containers/teachers/slot-calendar/calendar';
import StudentProfilePage from '../../containers/students/profile/profile';
import studentCalendarPage from '../../containers/students/slot-calendar/calendar';
import teacherViewPage  from '../../containers/teachers/view-details/index';
import studentViewPage from '../../containers/students/view-details/index';
import AddTeacherProfilePage from "../../containers/teachers/add-teacher/add-teacher";
import AddStudentProfilePage from "../../containers/students/add-student/add-student";
import TeacherSlotSearch from "../../containers/teachers/teacher-utils/teacher-slot-search"
import WebinarMasterClass from "../../containers/teachers/webinar/masterclass"
import Referral from "../../containers/teachers/referrals/referrallink"
import Refund from "../../containers/students/payment/refund-request"
import reportPage  from '../../containers/admin/report/class-report/filter';
import addonReportPage from '../../containers/admin/report/addon-report/index';
import teacherCompensationReportPage from '../../containers/admin/report/teacherCompensationReport';
import  bulkemailsPage from '../../containers/email/bulk-email';
import Chat from '../../containers/chat';
import Status from '../../containers/teachers/demostatus/status';
import Restart from '../../containers/teachers/courseService/restart';
import CategoryWiseAeSlots from '../../containers/teachers/aereports/categoryWiseAeReports';
import AeConversion from '../../containers/teachers/aereports/aeConversion';
import PaidSlots from '../../containers/teachers/aereports/paidSlots';
import TeacherForecast from '../../containers/admin/report/teacherForecast';
import PayoutPolicy from '../../containers/TeacherLevel/payoutPolicy';
import AuditTrail from '../../containers/Assignment/auditTrail';
import Certificate from '../../containers/teachers/certificates/certificate';
import WeeklyReport from '../../containers/teachers/aereports/weeklyReport';
import TeacherLeave from '../../containers/LeavePolicy/teacherLeave';
import TeacherPayout from '../../containers/admin/report/teacherPayout';
import FindTeacherStudent from '../../containers/FindTeacherStudent/FindTeacherStudent';
import AssignList from '../../containers/Assignment/AssignList';
import DateView from '../../containers/teachers/aereports/dateView';
import create from '../../containers/batch/create';
import CreateOffline from '../../containers/batch/createOffline';
import BatchCreation from '../../containers/batch/BatchCreation';
import BatchCreationOffline from '../../containers/batch/BatchCreationOffline';
import DetailViewOps from '../../containers/DemoOperation/DetailViewOps';
import AahaMoment from '../../containers/AAHA/AahaMoment';
import TeacherQa from '../../containers/TeacherQas/TeacherQa';
import DemopsShift from '../../containers/demopshift/demopsShift';
import QaReports from '../../containers/TeacherQas/QaReports';
import RegForm from '../../containers/batch/RegForm';
import DemoOps from '../../containers/DemoOperation/DemoOps';
import teacherChange from '../../containers/teacherChange/teacherChange'
// defining app pages
export default {
    loginPage,
    teachersPage,
    studentsPage,
    profilePage,
    teacherProfilePage,
    teacherCalendarPage,
    StudentProfilePage,
    studentCalendarPage,
    teacherChange,
    DemopsShift,
    teacherViewPage,
    studentViewPage,
    AddTeacherProfilePage ,
    AddStudentProfilePage ,
    reportPage,
    addonReportPage,
    teacherCompensationReportPage,
    TeacherSlotSearch,
    WebinarMasterClass,
    Referral,
    Refund,
    TeacherLeave,
    AahaMoment,
    PaidSlots,
    TeacherForecast,
    bulkemailsPage,
    Status,
    Restart,
    CategoryWiseAeSlots,
    AeConversion,
    PaidSlots,
    QaReports,
    RegForm,
    DateView,
    WeeklyReport,
    FindTeacherStudent,
    AssignList,
    Certificate,
    Chat,
    DemoOps,
    DetailViewOps,
    TeacherPayout,
    create,
    RegForm,
    BatchCreation,
    TeacherQa,
    CreateOffline,
    BatchCreationOffline,
    PayoutPolicy,
    AuditTrail,

};
