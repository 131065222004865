import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message, InputNumber, Select, notification, Spin, DatePicker } from 'antd';
import moment from "moment"; 
import * as SecureLS from "secure-ls";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import appEnvirontVariables from "../../../src/core_units/config/env-variables";
import gmail from "../../../src/assets/img/Gmail.svg";
import './style.css';
const { Option } = Select;

const FindTeacherStudent = (props) => {
  var ls = new SecureLS({ encodingType: "aes" });
  const token = ls.get("isLogin");
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [courseList, setCourseList] = useState();
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState([]);
  const [selectedlanguage, setselectedlanguage] = useState([]);
  const [fileCourse, setFileCourse] = useState("");
  const [fileDay, setFileDay] = useState("");
  const [file, setFile] = useState("");
  const [feedbackList, setFeedbackList] = useState();
  const [tableData, setTableData] = useState([]);
  const [optionListDetails, setOptionListDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTeacherDetail, setSelectedTeacherDetail] = useState(null);




  const customTime = [
    { optionname: "00:00 AM", optionvalue: 1 },
    { optionname: "00:30 AM", optionvalue: 1 },
    { optionname: "01:00 AM", optionvalue: 1 },
    { optionname: "01:30 AM", optionvalue: 1 },
    { optionname: "02:00 AM", optionvalue: 1 }, 
    { optionname: "02:30 AM", optionvalue: 1 },
    { optionname: "03:00 AM", optionvalue: 1 },
    { optionname: "03:30 AM", optionvalue: 1 },
    { optionname: "04:00 AM", optionvalue: 1 },
    { optionname: "04:30 AM", optionvalue: 1 },
    { optionname: "05:00 AM", optionvalue: 1 },
    { optionname: "05:30 AM", optionvalue: 1 },
    { optionname: "06:00 AM", optionvalue: 1 },
    { optionname: "06:30 AM", optionvalue: 1 },
    { optionname: "07:00 AM", optionvalue: 1 },
    { optionname: "07:30 AM", optionvalue: 1 },
    { optionname: "08:00 AM", optionvalue: 1 },
    { optionname: "08:30 AM", optionvalue: 1 },
    { optionname: "09:00 AM", optionvalue: 1 },
    { optionname: "09:30 AM", optionvalue: 1 },
    { optionname: "10:00 AM", optionvalue: 1 },
    { optionname: "10:30 AM", optionvalue: 1 },
    { optionname: "11:00 AM", optionvalue: 1 },
    { optionname: "11:30 AM", optionvalue: 1 },
    { optionname: "12:00 PM", optionvalue: 1 },
    { optionname: "12:30 PM", optionvalue: 1 },
    { optionname: "01:00 PM", optionvalue: 1 },
    { optionname: "01:30 PM", optionvalue: 1 },
    { optionname: "02:00 PM", optionvalue: 1 },
    { optionname: "02:30 PM", optionvalue: 1 },
    { optionname: "03:00 PM", optionvalue: 1 },
    { optionname: "03:30 PM", optionvalue: 1 },
    { optionname: "04:00 PM", optionvalue: 1 },
    { optionname: "04:30 PM", optionvalue: 1 },
    { optionname: "05:00 PM", optionvalue: 1 },
    { optionname: "05:30 PM", optionvalue: 1 },
    { optionname: "06:00 PM", optionvalue: 1 },
    { optionname: "06:30 PM", optionvalue: 1 },
    { optionname: "07:00 PM", optionvalue: 1 },
    { optionname: "07:30 PM", optionvalue: 1 },
    { optionname: "08:00 PM", optionvalue: 1 },
    { optionname: "08:30 PM", optionvalue: 1 },
    { optionname: "09:00 PM", optionvalue: 1 },
    { optionname: "09:30 PM", optionvalue: 1 },
    { optionname: "10:00 PM", optionvalue: 1 },
    { optionname: "10:30 PM", optionvalue: 1 },
    { optionname: "11:00 PM", optionvalue: 1 },
    { optionname: "11:30 PM", optionvalue: 1 },
];




  useEffect(() => {
    configjson();
    feedbackjson();
  }, []);

  const configjson = () => {
    props.courseList({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCourseList(data);
        }
      },
    });
  };

  const feedbackjson = () => {
    props.feedbacklist({
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setFeedbackList(data);
          const optionDetails = data.teacherToStudentDemoFeedbackAirlineBooking.find((item) => item.id == 14);
          setOptionListDetails(optionDetails);
          console.log(optionDetails, "optionDetails==");
          console.log(data.teacherToStudentDemoFeedbackAirlineBooking.find((item) => item.id == 14), "pppppppp");
        }
      },
    });
  };

  const onCourseChange = (event) => {
    console.log(event, "eventooo====");
    setFileCourse(event);
    console.log(event, "event");
  };

  const onFileChange = (event) => {
    console.log(event, "eventooo====");
    setFile(event);
    console.log(event, "event");
  };

  const onDayChange = (event) => {
    console.log(event, "eventooo====");
    setFileDay(event);
    console.log(event, "event");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      let classBook;
      if (values.classestoBook) {
        classBook = values.classestoBook;
      }
      const requestBody = {
        studentId: props.profileDetails?.studentId,
        selectedTime: file,
        courseId: fileCourse,
        selectedDay: fileDay,
        ...(classBook && { classestoBook: classBook }),
        startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : null,
      };

      if (values.ageGroupId) {
        requestBody.ageGroupId = values.ageGroupId;
      } 

      if (values.studentLanguageId) {
        requestBody.studentLanguageId = values.studentLanguageId;
     
      }

      
      if (selectedGender) {
        requestBody.sgender = selectedGender;
      } else {
        if (props.profileDetails?.gender) {
          requestBody.sgender = props.profileDetails.gender;
        }
      }

      const response = await axios.post(
        `${appEnvirontVariables.API.BASE_URL}/api/course/find/teacher`,
        requestBody,
        {
          headers: {
            Authorization: token["authorization"],
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        if (Array.isArray(response.data)) {
          setTableData(response.data);
        } else {
          setTableData([]);
          notification.error({
            message: "No Teacher available",
          });
        }
      } else if (response.status === 204) {
        notification.error({
          message: "No Data Found",
        });
        setTableData([]);
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const getUtilizationColor = (utilization) => {
    if (utilization >= 0 && utilization <= 50) {
      return "lightgreen";
    } else if (utilization > 50 && utilization <= 80) {
      return "orange";
    } else {
      return "red";
    }
  };

  const selectTeacherChange = (e, item) => {
    setSelectedTeacherDetail(item);
  }
  const assignTeacher = () => {
    if(selectedTeacherDetail){
      props.assignTeacher(selectedTeacherDetail);
      props.handleFTCancel();
    }else{
      notification.error({
        message: "",
        description: `Please select a teacher to assign.`,
      });
    }
  }

  return (
    <div>
      <div className="report-card" style={{ overflow: "auto", boxShadow:"none" }}>
        <div className="report-filter-conatiner">
          <span><b>Details</b></span>
          <div className="row" style={{ paddingTop: "10px" }}>
            <Form
              className="form-content-width"
              form={form}
              autoComplete="off"
            >
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="col">
                <div style={{textAlign:'right',fontSize:'12px',visibility:'hidden'}}> Dummy text</div>
                  <Form.Item
                    label="Course"
                    name="courseId"
                    style={{ width: "auto" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your course!",
                      },
                    ]}
                  >
                    <Select placeholder="Please Select Course" onChange={onCourseChange} allowClear style={{ width: "266px", background: "#f5f8fa", marginLeft: "-73px" }}>
                      {_.map(
                        _.get(courseList, "courses"),
                        (course, eachParent) => {
                          return _.map(
                            _.get(course, "tags"),
                            (tag, each) => {
                              return (
                                <Option
                                  value={_.get(tag, "paidCourseId")}
                                  key={eachParent + "-" + each}
                                >
                                  {_.get(course, "name")}-
                                  {_.get(tag, "name")}
                                </Option>
                              );
                            }
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col">
                <div style={{textAlign:'right',fontSize:'11px'}}> Time is shown in {props.profileDetails?.timezoneAbbreviations} {props.profileDetails?.gmtVaule}</div>
                  <Form.Item
                    label="Custom Time"
                    name="selectedTime"
                    style={{ width: "auto" }}
                    rules={[
                      {
                        required: true,
                        message: "Please Select the Time!",
                      },
                    ]}
                  >
                     <Select placeholder="Please Select Time" onChange={onFileChange} allowClear style={{ width: "232px", background: "#f5f8fa", marginLeft: "-41px" }}>
                      {customTime.map((option, index) => (
                        <Option key={index} value={option.optionname}>
                          {option.optionname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  
                </div>

                <div className="col">
                <div style={{textAlign:'right',fontSize:'12px',visibility:'hidden'}}> Dummy text</div>
                  <Form.Item
                    label="Day"
                    className=""
                    name="selectedDay"
                    style={{ width: "auto" }}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Day!",
                      },
                    ]}
                  >
                    <Select
                      onChange={onDayChange}
                      allowClear
                      style={{ width: "266px", background: "#f5f8fa", marginLeft: "-94px" }}
                      dropdownClassName="course-list-dropdown"
                      placeholder="Please Select Day"
                    >
                      <Option value="MONDAY">
                        Monday
                      </Option>
                      <Option value="TUESDAY">Tuesday</Option>
                      <Option value="WEDNESDAY">Wednesday</Option>
                      <Option value="THURSDAY">Thursday</Option>
                      <Option value="FRIDAY">Friday</Option>
                      <Option value="SATURDAY">Saturday</Option>
                      <Option value="SUNDAY">Sunday</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="col">
                  <Form.Item
                    label="Age group"
                    name={"ageGroupId"}
                    rules={[]}
                    initialValue={parseInt(props?.profileDetails?.ageGroupId)}
                  >
                    <Select
                      placeholder="Please Select Age group"
                      allowClear
                   
                      // onChange={(value) => setSelectedAgeGroup(parseInt(value))}
                      style={{ width: "266px", background: "#f5f8fa", marginLeft: "-71px" }}
                    >
                      {_.map(_.get(courseList, "ageGroups"), (agegroups, each) => {
                        return (
                          <Option value={parseInt(_.get(agegroups, "id"))} 
                          key={each}>
                            {_.get(agegroups, "name")}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item
                    label="Languages"
                    name={"studentLanguageId"}
                    rules={[]}
                    initialValue={props.profileDetails?.languageId[0]?.id}
                  >
                    <Select placeholder="Please Select Language" style={{ width: "238px", background: "#f5f8fa", marginLeft: "-41px" }} 
                    allowClear>
                      {_.map(
                        _.get(courseList, "languages"),
                        (languages, each) => {
                          return (
                            <Option
                              value={parseInt(_.get(languages, "id"))}
                              key={each}
                            >
                              {_.get(languages, "name")}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col">
                  <Form.Item
                    name="sgender"
                    label="Gender"
                   initialValue={props.profileDetails?.gender}
                  >
                    <Select
                      placeholder="Please Select Gender"
                      style={{ width: "266px", background: "#f5f8fa", marginLeft: "-90px" }}
                      allowClear
                      onChange={(value) => setSelectedGender(value)}
                    >
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="col">
                  <Form.Item
                    name="classestoBook"
                    label="Classes to Book"
                  >
                    <Select
                      placeholder="Please Select classes to Book"
                      style={{ width: "266px", background: "#f5f8fa", marginLeft:"-30px" }}
                      allowClear
                    >
                      {[...Array(101).keys()].map((number) => (
                        <Option value={number} key={number}>{number}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col" style={{ display: 'flex', justifyContent: 'space-between', marginLeft:"-220px"}}>
                  <Form.Item
                    name="startDate"
                    style={{marginLeft:"-30px"}}
                    label="First Class Date"
                    rules={[
                      {
                        required: true,
                        message: "Please Select First Class Date!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const selectedDay = getFieldValue('selectedDay');
                          if (selectedDay && value) {
                            const selectedDayOfWeek = moment(value).day();
                            const selectedDayOfWeekNumeric = moment().day(selectedDay).day();
                            if (selectedDayOfWeek !== selectedDayOfWeekNumeric) {
                              return Promise.reject('The selected date does not match the selected day.');
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <DatePicker />
                  </Form.Item>
                </div>
              </div>
              <Form.Item style={{ display: "flex", float: "right", marginLeft: "-31px" }}>
                <Button onClick={handleSubmit}>Submit</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      {tableData.length > 0 &&
        <div className='calendar-legendset-conatiner' style={{ marginLeft: "-1px" }}>
          <div className='calendar-legend-set' style={{ marginTop: "20px" }}>
            <span className='legend-square' style={{ backgroundColor: 'lightgreen' }}>
            </span>
            <span className='legend-text'>
              0-50 &emsp;
            </span>
            <span className='legend-square' style={{ backgroundColor: 'orange' }}>
            </span>
            <span className='legend-text'>
              50-80 &emsp;
            </span>
            <span className='legend-square' style={{ backgroundColor: 'red' }}>
            </span>
            <span className='legend-text'>
              80 above &emsp;
            </span>
          </div>
        </div>
      }

      <div>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="large" tip="Loading..." style={{ textAlign: "center" }} />
          </div>
        ) : (
          tableData.length > 0 ? (
            <div>
              <table className="table table-bordered table-hover" id="tab_logic">
                <thead>
                  {tableData.length > 0 &&
                    <tr>
                      {props.fromPackage && <th>Select Teacher</th>}
                      <th className="text-center">Teacher Name</th>
                      <th className="text-center">Teacher Level</th>
                      <th className="text-center">Profile Link</th>
                      <th className="text-center">No.of Active Students</th>
                      <th className="text-center">Total No.Of Students</th>
                      <th className="text-center">Missed Slot</th>
                    </tr>
                  }
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((item, idx) => (
                      <tr key={idx}>
                        {props.fromPackage && <td style={{ backgroundColor: getUtilizationColor(item.utilization) }}>
                          <input type="radio" name="selectTeacher" onChange={(e) => selectTeacherChange(e,item)} />
                        </td> }
                        <td style={{ backgroundColor: getUtilizationColor(item.utilization) }}>
                          {item.userDetails.firstName} {item.userDetails.lastName}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.payoutCategory || ""}
                        </td>                        
                        <td>
                          <a href={item.photolinkdetails.videolink} target={"_blank"} rel="noopener noreferrer">
                            {item.photolinkdetails.videolink}
                          </a>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.activeStudentCount}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.paidStudentCount ? item.paidStudentCount : 0}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.missedCount || 0}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {props.fromPackage && <Button type="primary" onClick={(e) => { assignTeacher() }} style={{ display: 'inline-block', paddingRight: '10px' }}><b>Assign Teacher</b></Button>}
            </div>
          ) : (
            <div style={{ textAlign: "center" }}></div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  courseList: (details) =>
    dispatch(global.redux.action.students.courseList(details)),
  feedbacklist: (details) =>
    dispatch(global.redux.action.students.feedbackList(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FindTeacherStudent);