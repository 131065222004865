import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import "./style.css";
import logo from "../../../assets/gif/aa_loader.gif";
import boy1 from "../../../assets/img/default-image.png";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
// import { Pagination } from 'react-bootstrap';  
import {
  Checkbox,
  Modal,
  Menu,
  Select,
  Empty,
  notification,
  Form,
  Input,
  Button,
  Spin,
} from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { ExclamationCircleOutlined, DiffOutlined } from "@ant-design/icons";
import moment from "moment";
import "../list/teachers.css";
const { Search } = Input;
const StudentListAe = (props) => {
  console.log(props, "props===");
  const { confirm } = Modal;
  let history = useNavigate();
  const navigate = useNavigate();
  const { Option } = Select;
  const [teacher] = useSearchParams();
  const [studentList, setStudentList] = useState([]);
  console.log(studentList, "studentList");
  const [studentElement, setStudentElement] = useState();
  const [isModalVisible, setIsModalVisible] = useState(props.scheduleslot);
  const [student, setStudent] = useState();
 const[studentCount,setStudentCount] = useState();
 const[searchValue,setSearchValue]= useState('');
  const [countList, setCountList] = useState();
  const [page, setPage] = useState(1);
  console.log(page,"===")
  const [activeMenu, setActiveMenu] = useState('DEMO');
  const [studentListCount, setStudentListCount] = useState([]);
  console.log(studentListCount,"!!!==")
  console.log(countList, "count==");
  const [pageloading, setpageloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  

  const onFinish = (values) => {
    let req = {
      studentId: 271,
      courseId: 15,
      teacherId: 70,
      selectedSlots: [
        {
          teacherSlotInfoId: 15267,
          slotDate: _.get(props, "slot.slotDate"),
          slotStartTime: _.get(props, "slot.startTime"),
          slotEndTime: _.get(props, "slot.endTime"),
        },
      ],
    };
  };

  const onFinishFailed = (errorInfo) => {
  };
  useEffect(() => {
    if(_.get(props, "scheduleslot")){
      setIsModalVisible(_.get(props, "scheduleslot"));
      activeCountClass();
    }
   
  }, [_.get(props, "scheduleslot")]);
  useEffect(() => {
    if (searchValue === "") {
      activeCountClass(); // Load all the data
    }
  }, [searchValue]);

  const activeCountClass = () => {
    setIsLoading(true);
    props.activeInactiveClass({
      body: {
        teacherId: props.profile.teacherId,
         request: searchValue
      },
      callback: (res, data) => {
        if (_.isEqual(res, true)) {
          setCountList(data);
          setStudentListCount(data.Students.DEMO.data);
          console.log(data.Students.DEMO.data, "datalist===");
        }
        setIsLoading(false);
      },
    });
  };
  const onSearch = (value) => {
    setIsLoading(true);
		activeCountClass(value)
    setSearchValue(value)
    console.log(value,"qqq==")
	}
  const onReset = () => {
    setSearchValue("");
    // activeCountClass();
    
  };
  const oncheckboxchange = (e, key) => {
    setStudentCount(key);
    let reqbody = {
      studentId: _.get(key, "studentId"),
      courseId: _.get(key, "studentCourse"),
      teacherId: _.get(props, "scheduleevent.teacherId"),
      selectedSlots: [
        {
          teacherSlotInfoId: _.get(props, "scheduleevent.teacherSlotInfoId"),
          slotDate: moment(_.get(props, "scheduleevent.slotDate")).format(
            "DD/MM/YYYY"
          ),
          slotStartTime: moment(
            _.get(props, "scheduleevent.startTime"),
            "HH:mm"
          ).format("HH:mm"),
          slotEndTime: moment(
            _.get(props, "scheduleevent.endTime"),
            "HH:mm"
          ).format("HH:mm"),
        },
      ],
    };
    let search = Object.fromEntries([...teacher]);
    delete search.tab;
    let searchdata = new URLSearchParams(search).toString();
    if (_.isEqual(_.get(e, "target.checked"), true)) {
      confirm({
        title: "Do you Want to do this action?",
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <span>
              Date:{" "}
              {_.upperCase(
                moment(_.get(props, "scheduleevent.slotDate")).format(
                  "DD MMM YYYY"
                )
              )}
            </span>
            <br />
            <span>
              Timing:{" "}
              {moment(_.get(props, "scheduleevent.startTime"), "HH:mm").format(
                "HH:mm"
              )}
              -
              {moment(_.get(props, "scheduleevent.endTime"), "HH:mm").format(
                "HH:mm"
              )}
            </span>
            <br />
            <span>Student: {_.startCase(_.get(key, "studentName"))}</span>
          </div>
        ),
        onOk() {
          setpageloading(true);
          props.scheduleClass({
            body: reqbody,
            callback: (res, data) => {
              setpageloading(false);
              if (_.isEqual(res, true)) {
                history(`/teacher-view?${searchdata}&tab=1`, {});
                notification.success({
                  message: "Class scheduled successfully.",
                });
              } else {
                notification.error({
                  message: "Something went wrong",
                });
              }
            },
          });
        },
        onCancel() {
          history(`/teacher-view?${searchdata}&tab=1`, {});
        },
      });
    }
  };
  const render = () => {
    return (
      <Form onFinish={onFinish}>
      <div className="payment-container">
        {isLoading ? (
          <div className="load">
            <Spin tip="Loading..." style={{ textAlign: "center" }} />
          </div>
        ) : (
          _.size(countList) > 0 && (
            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8" style={{ width: "100%" }}>
                <div style={{ marginTop: 20 }}>
                  <Search
                    placeholder="Search by email/mobile number and press search button"
                    allowClear
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={onSearch}
                    enterButton="Search"
                    style={{ width: 300, float: 'right' }}
                  />
               <Button style={{ marginLeft: "94%", width:"6%",marginTop:"10px",backgroundColor:"lightgreen"}} onClick={onReset}>
                      Reset
                    </Button>
                </div>
               
                <div className="d-flex flex-wrap boxSize">
                  <div
                        className={
                          activeMenu == "DEMO"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(countList?.Students?.DEMO?.data);
                          setActiveMenu("DEMO");
                          setSearchValue("");
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                          <div className="fs-2 fw-bolder">
                            {countList?.Students?.DEMO?.count}
                          </div>
                        </div>
                        <div className="fw-bold fs-6  ButtonState">
                          <b>DEMO</b>
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu == "PAIDACTIVE"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(
                            countList?.Students?.PAIDACTIVE?.data
                          );
                          setActiveMenu("PAIDACTIVE");
                          
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                          
                          <div className="fs-2 fw-bolder">
                            {countList?.Students?.PAIDACTIVE?.count}
                          </div>
                        </div>
                        <div className="fw-bold fs-6 ButtonState">
                          <b>PAIDACTIVE</b>
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu == "PAIDINACTIVE"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(
                            countList?.Students?.PAIDINACTIVE?.data
                          );
                          setActiveMenu("PAIDINACTIVE");
                         
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                          <div className="fs-2 fw-bolder">
                            {countList?.Students?.PAIDINACTIVE?.count}
                          </div>
                        </div>
                        <div className="fw-bold fs-6 ButtonState">
                          <b>PAIDINACTIVE</b>
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu == "paid"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(countList?.Students?.PAID?.data);
                          setActiveMenu("paid");
                        
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                          <div className="fs-2 fw-bolder">
                            {countList?.Students?.PAID?.count}
                          </div>
                        </div>
                        <div className="fw-bold fs-6 ButtonState">
                          <b>PAID</b>
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu == "NEWSTUDENTS"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(countList?.Students?.NEWSTUDENTS?.data);
                          setActiveMenu("NEWSTUDENTS");
                        
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                          <div className="fs-2 fw-bolder">

                            {countList?.Students?.NEWSTUDENTS?.count}

                          </div>
                        </div>
                        <div className="fw-bold fs-6 ButtonState">
                          <b> NEWSTUDENTS</b>
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu == "PAIDACTIVE(Payout)"
                            ? "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox Button"
                            : "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-2 mb-3 buttonbox"
                        }
                        onClick={() => {
                          setStudentListCount(countList?.Students["PAIDACTIVE(Payout)"]?.data);
                          setActiveMenu("PAIDACTIVE(Payout)");
                        
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                          <div className="fs-2 fw-bolder">

                            {countList?.Students["PAIDACTIVE(Payout)"]?.count}

                          </div>
                        </div>
                        <div className="fw-bold fs-6 ButtonState">
                          <b> PAIDACTIVE (Payout)</b>
                        </div>
                      </div>
                </div>
                {studentListCount == null ? (
                  <div className="singleCard">No Data Available</div>
                ) : (
                  <div className="TeacherStudent-card">
                    <div className="DashboardScreen-container" style={{
                      maxHeight: "80vh",
                      overflowY: "auto",
                      width: "100%",
                    }}>
                      <table className="Dashboard-card-screen student-grid">
                        <thead>
                          <tr>
                            <th className="table-cell">S.No</th>
                            <th className="table-cell">Name</th>
                            <th className="table-cell">Email</th>
                            <th className="table-cell">Mobile Number</th>
                            <th className="table-cell">Last Class Taken</th>
                            <th className="table-cell">Enrollment Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(studentListCount) && studentListCount.map((studentCount, index) => {
         
      const serialNumber = index + 1;
    
      return (
        <tr key={index}  style={{ width: "100%" }}>
          <td className="table-cell">{serialNumber}</td>
          <td className="table-cell">
            <Link
              to={{
                pathname: `/student-view`,
                search: `?id=${_.get(studentCount, "studentId")}&credid=${_.get(studentCount, "credentialId")}`
              }}
              state={studentCount}
            >
              {studentCount?.studentName}{activeMenu === 'NEWSTUDENTS' && studentCount?.previousteacherCount > 0 &&
    <span className="highlight" style={{fontSize:"16px",fontWeight:"600", marginLeft:"7px"}}>({studentCount.previousteacherCount})</span>}

            </Link>
          </td>
          <td className="table-cell">{studentCount.emailId}</td>
          <td className="table-cell">{studentCount.mobileNumber}</td>
          <td className="table-cell"> {moment(studentCount.lastClassTaken).format("DD/MM/YYYY HH:mm")}</td>
          <td className="table-cell">{moment(studentCount.enrollmentdate).format("DD/MM/YYYY HH:mm")}</td>
        </tr>
      );
    })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </Form> 
    );
  };
  return render();
};
const mapStateToProps = (state) => ({
  teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),
  students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
});
const mapDispatchToProps = (dispatch) => ({
  scheduleClass: (details) =>
    dispatch(global.redux.action.teachers.scheduleClass(details)),
  activeInactiveClass: (details) =>
    dispatch(global.redux.action.teachers.activeInactiveClass(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentListAe);