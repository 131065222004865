import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Tabs, Select, notification, Modal ,DatePicker} from "antd";


import Profile from "../profile/profile";
import ClassDeduction from "../deduction/classDeduction";
import Cashback from "../wallet/cashback";
import Arm from "../Arm/arm";
import PaymentLink from "../payment/paymentlink";
import TeacherlistAirline from "../payment/teacherlist_airline";
import Calendar from "../slot-calendar/calendar";
import AeForm from "../form/AeForm";
import KysForm from "../form/KysForm";
import Refund from '../payment/refund';
import PackageList from "../payment/package";
import { Link } from "react-router-dom";
import { useSearchParams, useLocation } from "react-router-dom";
import proimg from "../../../assets/img/default-image.png";
import logo from "../../../assets/gif/aa_loader.gif";
import vipstudent from "../../../assets/img/vipstudent.png"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./profile.css";
import * as SecureLS from "secure-ls";
import { event } from "jquery";
import { UsergroupAddOutlined } from "@ant-design/icons";
import StudentHistory from "../form/StudentHistory";
import axios from "axios";
import { Switch } from 'antd';
import appEnvirontVariables from "../../../core_units/config/env-variables";
import { constant } from '../../../core_units/constant';
import FindTeacherStudent from '../../../containers/FindTeacherStudent/FindTeacherStudent';
const { TabPane } = Tabs;
const { Option } = Select;
const mixpanel = require('mixpanel-browser');
const StudentViewPage = (props) => {
  var ls = new SecureLS({ encodingType: 'aes' });
  const loginDetails = ls.get('isLogin');
  const token = ls.get('isLogin');
  console.log(props,"light==")
  const [studentId] = useSearchParams();
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const [profile, setProfile] = useState();
  const [studentClassSchedule, setStudentClassSchedule] = useState([]);
  const [classcount, setclasscount] = useState();
  const [studentcourse, setstudentcourse] = useState();
  const [selectedcourse, setselectedcourse] = useState();
  console.log(selectedcourse,"selectedcourse")
  const [tabClickEvent, setTabClickEvent] = useState([]);
  const [studentClassId, setStudentClassId] = useState();
const [classScheduleIds,setClassScheduleIds] = useState({});
const [showModal, setShowModal] = useState(false);
  const [extendedDate, setExtendedDate] = useState(null);
  const [reason, setReason] = useState("");
  const [showReasonError, setShowReasonError] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [loading, setLoading] = useState(false);
const[expire,setExpire] = useState([]);
const [selectedMembership, setSelectedMembership] = useState(profile?.membershipType || "");
const [referralLink, setReferralLink] = useState(''); 
const [isLoading, setIsLoading] = useState(false);
const [shortUrl, setShortUrl] = useState(null);
const [isGenerating, setIsGenerating] = useState(false); 
const [isToggleOn, setIsToggleOn] = useState(profile?.
  isDeleted
   || false);

   useEffect(() => {
    if (profile?.membershipType) {
      setSelectedMembership(profile.membershipType); 
    }
  }, [profile?.membershipType]);
  
const [isModalVisible, setIsModalVisible] = useState(false);
console.log(isToggleOn,"isToggleOn")
  const handleExtendCourseClick = () => {
    setShowModal(true);
  };

  const handleExtendDateChange = (date) => {
    setExtendedDate(date);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setShowReasonError(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

 console.log(classScheduleIds,"classScheduleIds")
  const { confirm } = Modal;
  const callback = (key) => {
  
    setTabClickEvent(key);
  };

  useEffect(() => {
    console.log("profileApi",profile?.
    isDeleted)
    setIsToggleOn(profile?.
      isDeleted
       || false);
}, [profile?.
  isDeleted
  ]);
  
  useEffect(() => {
    apicall();
    //classcountcall();
    studentCourseList()
    //classScheduleId();
  }, []);

  useEffect(() => {
    if (selectedcourse) {

    }
  }, [selectedcourse]);

  useEffect(() => {
    setProfile(_.get(props, "students.viewprofile"));
    console.log("profile....", profile)
    setclasscount(_.get(props, "students.classcount"));
    // setStudentClassSchedule(_.get(props, 'students.classdetails'))
  }, [_.get(props, "students.viewprofile")]);

  useEffect(() => {
    setclasscount(_.get(props, "students.classcount"));
  }, [_.get(props, "students.classcount")]);
  const apicall = (dataprops) => {
    props.view({
      params: {
        studentId: studentId.get("credid"),
      },
      callback: (response, data) => {
        console.log(data,"responseprofile==")
      },
      
    });
  };
  // const classRearrangeBtnClick = () => {
  //     setIsModalVisible(true);
  //   };
  const studentCourseList = (yyyy) => {
    console.log("Calling enrolledcourselist API1");
   
    props.enrolledcourselist({
      params: {
        credid: studentId.get("credid"),
      },
      callback: (response, data) => {
        // setselectedcourse(data[0])
        setstudentcourse(data);
        if (data && data.length) {
          classcountcall({ courseId: data[0].courseId });
          setselectedcourse(data[0]);
          
        }
      },
    });
  };

  const studentClassRearrange = () => {
    if (selectedcourse?.courseId) {
     
      props.classRearrangement({
        params: {
          studentId: profile.studentId,

          courseId: selectedcourse.courseId,
        },
        callback: (response, data) => {
          // setselectedcourse(data[0])
          // setstudentcourse(data)
        },
      });
      notification.success({
        message: "class Rearrangement successfully done.",
      });
    } else {
      notification.error({
        message: "Please select one course!",
      });
    }
  };
  const expiryCourseExtend = () => {
   
    if (extendedDate && reason) {
        const dateObject = new Date(extendedDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

   
    const formattedDate = `${year}-${month}-${day} 00:00:00`;

      props.expiryDate({
        body: {
          studentId: profile.studentId,
          courseId: selectedcourse.courseId,
          expiryDate: formattedDate,
          modificationReason: reason,
        },
        callback: (response, data) => {
            setExpire(data);
           
        },
      });
      notification.success({
        message: "Expiry date successfully extended!",
      });
      props.enrolledcourselist({
        params: {
          credid: studentId.get("credid"),
        },
    });
      setExtendedDate(null);
      setReason("");
    } else {
   
    }
  };
  const studentSyncWithSalesForce = () => {
    confirm({
      title: "Do you Want to Sync with Salesforce?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (profile?.studentId) {
          props.syncSalesForce({
            params: {
              studentId: profile.studentId,
            },
            callback: (response, data) => {},
          });
          notification.success({
            message: "Sync with Salesforce successfully done.",
          });
        } else {
          notification.error({
            message: "Something went Wrong",
          });
        }
      },
    });
  };

  // const classScheduleId = (query) => {
  //   props.studentclass({
  //     params: {
  //       studentId: studentId.get("id"),
      
  //       ...query,
  //     },
  //     callback: (response, data) => {
  //       console.log(response, data, "response, data");
  //       setStudentClassSchedule(data.content);
  //       console.log(data.content, "data.content");
  //     },
  //   });
  // };
  const classcountcall = (query) => {
    props.classcount({
      params: {
        studentId: studentId.get("id"),
        ...query,
      },
      callback: (response, data) => {
        setclasscount(data);

      
      },
    });
  };
  const classRearrangeBtnClick = () => {
    setProfile();
    setselectedcourse();
  };

  const coursechange = (value, key) => {
   
    classcountcall({ courseId: key.value });
    setselectedcourse(studentcourse[key.key]);
    console.log(studentcourse[key.key],"studentcourse[key.key]")
  };
  const handleClickCourse = (event) => {
    console.log(event,"2")
 
    if (event) {
      studentCourseList();
     
    }
  };
  const handleSubmit = () => {
    if (reason.trim() === "") {
      setShowReasonError(true);
    } else {
      expiryCourseExtend();
      handleModalClose();
    }
  };


  const handleToggle = (checked) => {
    setIsToggleOn(checked);
    setIsModalVisible(true); 
  };



  const handleApiCall = () => {
        console.log("loginDetails", loginDetails);
    const actionParam = isToggleOn ? "true" : "false";
   if(selectedReason){
      axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/revoked/access/student?studentId=${profile.studentId}&action=${actionParam}`,
        {},
        {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        }
      )
      
      .then((response) => {
        console.log(response, "response===")
        if (response.status === 200) {
          setIsModalVisible(false);
          setSelectedReason("");
          
         
          const successMessage = isToggleOn
            ? "The student can login through Web and Apps."
            : "The Student Can't login through Web and Apps. Only viewable to Admins.";
          
          notification.success({
            description: successMessage,
          });
          
      
          mixpanel.track(constant.MIXPANEL_LEARNER_STATUS, {
            user: loginDetails?.studentName,
            Studentname: profile.firstName,
            studentId: profile.studentId,
            mobileNumber: profile.mobileNumber,
            enabled: isToggleOn,
            emailId: profile.emailId,
            reason: selectedReason
          });
        } else {
          notification.error({
            description: "Something went wrong",
          });
        }
      })
      
      .catch((error) => {
        console.error("Failed to revoke access:", error);
      });
   }
  };

  const generateReferral = async (userId, course) => {
    setIsGenerating(true); 
    console.log("Function called with:", { userId, course });
    console.log("Token:", token);
    console.log("Profile:", profile);

    try {
      const response = await axios.post(
       `${appEnvirontVariables.API.REFERRAL_STUDENT_URL}/api/referral/generateReferral`,
        {
          utmSource:loginDetails?.studentName,
          userId: profile.userId,
          role: profile.role,
          name: profile.firstName,
          mobileNumber: profile.mobileNumber,
          countryCode: profile.countryCode,
          ageGroup: profile.ageGroup,
          gender: profile.gender,
          courseName: selectedcourse.courseName || "",
          state: profile.state || "",
          clickType:"admin",
          studentType: profile.studentType,
        },
        {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response);

      if (response.status === 200) {
        const { shortUrl } = response.data; // Extract shortUrl from the response
        setShortUrl(shortUrl); // Set shortUrl to state
        notification.success({
          message: "Referral Generated",
          description: "Referral link generated successfully.",
        });
      } else {
        console.error("Error response:", response);
        notification.error({
          message: "Referral Failed",
          description: "Failed to generate referral link. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error generating referral:", error.response || error);
      notification.error({
        message: "Referral Error",
        description: "Something went wrong while generating the referral.",
      });
      setIsGenerating(false);
    }
  };
  
  


  const handleMembershipChange = (userId, newMembershipType, token) => {
  console.log("loginDetails",loginDetails);
    setSelectedMembership(newMembershipType);

   
    axios
      .post(
        `${appEnvirontVariables.API.BASE_URL}/api/admin/add/user/membership/type`,
        {
          userId: userId,
          membershipType: newMembershipType,
        },
        {
          headers: {
            Authorization: token["authorization"],
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            description: "Membership updated successfully.",
          });

          mixpanel.track(constant.MIXPANEL_STUDENT_MEMBERSHIP, {
            user: loginDetails?.studentName,
            Studentname: profile.firstName,
            studentId: profile.studentId,
            mobileNumber: profile.mobileNumber,
            enabled: isToggleOn,
            emailId: profile.emailId,
            reason: selectedReason,
            membershipType: newMembershipType ,

          });
        } else {
          notification.error({
            description: "Failed to update membership type.",
          });
        }
      })
      .catch((error) => {
        console.error("Failed to update membership:", error);
        // notification.error({
        //   // description: "Something went wrong while updating the membership.",
        // });

        // setSelectedMembership(profile?.membershipType);
      });
  };

  const showReasonModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsToggleOn(!isToggleOn);
    setIsModalVisible(false);
    setSelectedReason("");
  };

  const otherReasonchange = (e) => {
    setSelectedReason(e.target.value);
  }

 const generateCertificate = () => { 
  const requestBody = {
    userName: profile.firstName,
    date: new Date().toISOString().split('T')[0],
    userId: profile.studentId,
    course: selectedcourse.courseName,
  };

  setLoading(true); // Set loading to true

  axios
    .post(`${appEnvirontVariables.API.DEMO_CERTIFICATE_URL}/generate-certificate`, requestBody)
    .then((response) => {
      setLoading(false); // Set loading to false
      if (response.data.statusCode === 200) {
        notification.success({
          message: 'Certificate generated successfully!',
          description: (
            <div>
              <Button
          type="link"
          onClick={() => {
            navigator.clipboard.writeText(response.data.body.url);
            notification.success({
              message: 'Certificate link copied to clipboard!',
            });
            setTimeout(() => {
              notification.destroy();
            }, 2000); // Close the notification after 1 seconds
          }}
              >
          Copy Link
              </Button>
            </div>
          ),
          duration: 0, // This makes the notification non-disappearing
        });
      } else {
        notification.error({
          message: 'Failed to generate certificate',
        });
      }
    })
    .catch((error) => {
      setLoading(false); // Set loading to false
      console.error('Error generating certificate:', error);
      notification.error({
        message: 'Error generating certificate',
      });
    });
 }
 



  const render = () => {
    if (
      _.isEqual(_.get(props, "students.viewprofileLoadingInProgress"), false)
    ) {
      return (
        <div className="profile-container">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <img
                      src={
                        _.get(profile, "profilePhoto")
                          ? _.get(profile, "profilePhoto")
                          : proimg
                      }
                      alt="Metornic"
                    />
                    <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <a
                          href="#"
                          className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1"
                        >
                          {_.startCase(_.get(profile, "firstName", "No-name")) +
                            " " +
                            _.startCase(_.get(profile, "lastName", ""))}{" "}
                          | Student {selectedcourse && selectedcourse.isBatchEnrollment && (
             <UsergroupAddOutlined  style={{marginLeft:"20px", color:"darkgreen", fontSize:"31px"}}/>

             
            )}
                        </a>

                        <a href="#"></a>
                        {profile?.isVipStudent && (
    <span role="img" aria-label="VIP Student" style={{ marginTop: "-11px", marginLeft: "10px", fontSize: "24px" }}>
        🥇
    </span>
  )} 


                      </div>

                     
                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <span className="mr-2" style={{ fontSize: "15px" }}>
                          Course :
                          {studentcourse && (
                            <Select
                              defaultValue={_.get(studentcourse[0], "courseId")}
                              onChange={(value, key) => {
                                coursechange(value, key);
                              }}
                            >
                              {_.map(studentcourse, (course, i) => {
                              
                                return (
                                  <Option
                                    value={_.get(course, "courseId")}
                                    key={i}
                                  >
                                    {_.get(course, "courseName")}{" "}
                                  </Option>
                                );
                              })}
                              {/* <Option value='all' key={_.size(studentcourse)+1}>All </Option> */}
                            </Select>
                          )}
                        </span>

     
                      </div>
                      {selectedcourse && selectedcourse.demoCourse == "true" ? loading ? <button style={{width:"220px"}} className="btn-primary mb-4">Loading...</button> : <button onClick={generateCertificate} style={{width:"220px"}} className="btn-primary mb-4">Generate Demo Certificate</button> : ""}
                      <div className="d-flex align-items-center fw-bold fs-6 mb-4 pe-2" style={{ marginTop: "-10px" }}>
  <span className="mr-2" style={{ fontSize: "15px" }}>
    Membership:
  </span>
  <Select
    id="membershipTypeSelect"
    placeholder="Select Membership"
    value={selectedMembership || "Select Membership"} 
    onChange={(newMembershipType) => {
      setSelectedMembership(newMembershipType); 
      handleMembershipChange(profile?.userId, newMembershipType, token); 
    }}
    style={{ width: 208 }}
 
  >
      

    {/* <Option value="" disabled>
      Select Membership Type
    </Option> */}
    <Option value="GOLDMEMBERSHIP">Gold</Option>
    <Option value="PLATINUMMEMBERSHIP">Platinum</Option>
    <Option value="NULL">None</Option>
  </Select>
</div>
<div className="d-flex align-items-center fw-bold fs-6 mb-4 pe-2" style={{ marginTop: "-10px" }}>
  {/* Referral Link Section */}
  <span className="mr-2" style={{ fontSize: "15px" }}>Referral Link:</span>

  {/* Show referral link if available */}
  {shortUrl ? (
    <a
      href={shortUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontSize: "15px", marginLeft: "10px" }}
    >
      {shortUrl}
    </a>
  ) : (
    <span style={{ fontSize: "15px", marginLeft: "10px", color: "gray" }}>

    </span>
  )}

  {/* Button or Loading Message */}
  {!shortUrl && (
    <div style={{ marginLeft: "10px" }}>
      {!isGenerating ? (
        <Button onClick={generateReferral} style={{ fontSize: "14px" }}>
          Generate Referral
        </Button>
      ) : (
        <span style={{ fontSize: "14px", color: "gray" }}>Generating...</span>
      )}
    </div>
  )}
</div>




                      <div style={{ marginBottom: "3px", marginTop: "-7px",color:"red" }}>
  {selectedcourse && selectedcourse.expiryDate ? (
    <span>
      course will be expired on{" "}
      <label>{new Date(selectedcourse.expiryDate).toLocaleDateString()}</label>
    </span>
  ) : null}
  {selectedcourse && selectedcourse.expiryDate ? (
       <Button className='ml-2 ant-btn ant-btn-primary'  onClick={handleExtendCourseClick}> Extend Course</Button> 
        ) : null}
</div>

                      {_.size(selectedcourse) > 0 && (
                        <span className="mr-2" style={{ fontSize: "15px", marginBottom:"5px"}}>
                          Teacher : {" "}
                          {_.startCase(
                            _.get(selectedcourse, "teacherFirstName", "No-name")
                          ) +
                            " " +
                            _.startCase(
                              _.get(selectedcourse, "teacherLastName", "")
                            )}
                        </span>
                      )}

                      {selectedcourse &&
                        selectedcourse?.workingStatus == "TERMINATED" && (
                          <div
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              marginBottom: "10px",
                            }}
                            className="statusteach"
                          >
                            Teacher is Terminated
                          </div>
                        )}

                      {/* {_.map(studentcourse, (course, i)=> {
                                                return(
                                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" >
                                                    <span className='mr-2' style=
                                                        {{ fontSize: '15px' }}>Course : { _.get(course, 'courseName')}</span>|
                                                    <span style=
                                                        {{ fontSize: '15px' }}>Teacher : {_.startCase(_.get(course, 'teacherFirstName', 'No-name')) + ' ' + _.startCase(_.get(course, 'teacherLastName', ''))} </span>
                                                </div>
                                                )
                                            })} */}

                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        {_.get(profile, "mobileNumber") && (
                          <a
                            href="#"
                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                          >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mh-50px"
                              >
                                <path
                                  opacity="0.3"
                                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            {_.get(profile, "mobileNumber")}
                          </a>
                        )}
                        {_.get(profile, "currentAddressCountry") && (
                          <a
                            href="#"
                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                          >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mh-50px"
                              >
                                <path
                                  opacity="0.3"
                                  d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            {_.get(profile, "currentAddressCountry")}
                          </a>
                        )}
                        {_.get(profile, "emailId") && (
                          <a
                            href="#"
                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                          >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mh-50px"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            {_.get(profile, "emailId")}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap">
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                            <div className="fs-2 fw-bolder">
                              {_.get(classcount, "noOfClassesPending", 0)}
                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Unscheduled
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-3 svg-icon-danger me-2"></span>
                            <div className="fs-2 fw-bolder">
                              {_.get(classcount, "completeClass", 0)}
                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Completed
                          </div>
                        </div>
                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                          <div className="d-flex align-items-center">
                            <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                            <div className="fs-2 fw-bolder">
                              {_.get(classcount, "scheduledClassaddonCount", 0)}
                            </div>
                          </div>
                          <div className="fw-bold fs-6 text-gray-400">
                            Scheduled
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{float:"right"}} className="mb-2"><label style={{fontWeight:"600"}}>Learner Status</label>
                          
                          
                          <Switch
                   key={profile?.
                    isDeleted
                    } 
                  
                  style={{ marginLeft: "10px" }}
                  mask={true}
                  maskClosable={false}
                  checked={isToggleOn}
                  onChange={handleToggle} 
                  onClick={showReasonModal}
              />
                 
              
                          </div> */}
                          <Modal
  title="Select a Reason"
  visible={isModalVisible}
  onOk={handleApiCall}
  onCancel={handleCancel}
>

<input className='form-control mt-2' type='input' value={selectedReason} onChange={otherReasonchange} placeholder='Please enter the reason'/>

</Modal>
                </div>

                <div>
                
                  {_.isEqual(_.get(profile, "studentType"), "PAID") &&
                    _.get(classcount, "noOfClassesPending", 0) > 0 && selectedcourse &&
                    selectedcourse?.workingStatus != "TERMINATED" && (
                      <Button
                        className="mb-2 ant-btn ant-btn-primary"
                        disabled={
                          !_.isEmpty(selectedcourse) &&
                          _.isEqual(
                            _.get(selectedcourse, "demoCourse"),
                            "false"
                          )
                            ? false
                            : true
                        }
                      >
                        
                        <Link
                          to={`/calendar?id=${_.get(
                            selectedcourse,
                            "teacherId"
                          )}&&from=student`}
                          state={{
                            ...profile,
                            selectedCourse: selectedcourse,
                            studentCourseId: _.get(selectedcourse, "courseId"),
                            studentClassSchedule: studentClassSchedule,
                           
                            classScheduleId: _.get(
                              studentClassSchedule,
                              "courseId"
                            ),
                          }}
                        >
                          Schedule class
                        </Link>
                      </Button>
                    )}
                </div>

                <div className=""></div>
               

                {profile?.studentType == "PAID" &&
                  selectedcourse?.demoCourse == "false" && (
                    <span
                      style={{
                        position: "absolute",
                        marginTop: "82px",
                        marginRight: "-14px",
                        right: "44px",
                        cursor: "pointer",
                        background: "#0078d4",
                        padding: "5px 10px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        studentClassRearrange();
                      }}
                    >
                      Class Rearrange
                    </span>
                  )}
                {profile?.studentType == "PAID" && (
                  <span
                    style={{
                      position: "absolute",
                      marginTop: "167px",
                      marginRight: "-14px",
                      right: "44px",
                      cursor: "pointer",
                      background: "#0078d4",
                      padding: "5px 10px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      studentSyncWithSalesForce();
                    }}
                  >
                    Sync Salesforce
                  </span>
                )}
                
              </div>
              <div>
  {/* Display Membership label */}
  {/* <span
    style={{
      position: "absolute",
      marginRight: "-14px",
      right: "44px",
      cursor: "pointer",
      background: "#0078d4",
      padding: "5px 10px",
      color: "white",
      borderRadius: "5px",
    }}
  >
    Membership
  </span> */}

  {/* Dropdown for membership type */}
 
</div>

              <Tabs defaultActiveKey="1" onChange={callback}>
                
                <TabPane tab="Profile" key="1">
                  <Profile handleClickCourse={handleClickCourse} />
                </TabPane>
                <TabPane tab="Calendar" key="2">
                  <Calendar
                    selectedcourse={selectedcourse}
                    profileDetails={profile}
                    setStudentClassSchedule=
                    {setStudentClassSchedule} 
                    setClassScheduleIds={setClassScheduleIds}
                  />
                </TabPane>
                <TabPane tab="New Payment Link" key="3">
                <PackageList {...props}  studentcourse={studentcourse} profileDetails={profile} />

                </TabPane>
                <TabPane tab="Renewal Payment Link" key="4">
                  <PaymentLink  {...{...props,...{isOffline:!!location?.state?.isOffline}}} />
                </TabPane>

                <TabPane tab="Find Teacher" key="5">
                  <FindTeacherStudent profileDetails={profile}/>
                  {/* <TeacherlistAirline
                    studentClassSchedule={studentClassSchedule}
                    classScheduleIds={classScheduleIds}
                    tabClickEvent={tabClickEvent}
                    selectedcourse={selectedcourse}
                    profileDetails={profile}
                  /> */}
                </TabPane>
                <TabPane tab="Deducted Class Details" key="6">
<ClassDeduction/>
                </TabPane>
                <TabPane tab="Wallet Details" key="12">
<Cashback {...props} />
                </TabPane>
                {profile?.membershipType && profile?.studentType !== "DEMO" && (
  <TabPane tab="ARM" key="7">
    <Arm selectedCourse={selectedcourse} profile={profile} />
  </TabPane>
)}

                <TabPane tab="AE Form" key="8">
<AeForm {...props}  selectedcourse={selectedcourse}/>
                </TabPane>
                <TabPane tab="KYS Form" key="9">
<KysForm {...props}  selectedcourse={selectedcourse}/>
                </TabPane>
                <TabPane tab="Transaction List" key="10">
<Refund profile={{ ..._.get(props, 'students.viewprofile'), ...{credId: studentId.get("credid")}, ...{token}}} />
                </TabPane>
                <TabPane tab="Student History" key="11">
                <StudentHistory {...props} profileDetails={profile} />

                </TabPane>
              </Tabs>
            </div>
          </div>
          {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "50%",
              marginLeft: "137px",
            }}
          >
            <h2>Extend Course Details</h2>

            <label htmlFor="extendedDate">Date:</label>
            
            <DatePicker
              id="extendedDate"
              selected={extendedDate}
              onChange={handleExtendDateChange}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
            />
            <br />

            <label>Reason For Extend:</label>
            
            <input
              type="text"
              id="reason"
              value={reason}
              onChange={handleReasonChange}
              style={{ width: "314px", marginLeft: "10px", border: "1px solid" }}
              
            />
             {showReasonError && (
        <p style={{ color: "red", margin: "5px 0" }}>
          Please enter the reason for extend.
        </p>
      )}
            <br />

            <div>
              <Button
              type="primary"
                style={{
                  float: "right",
                
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
               type="primary"
                style={{
                  float: "right",
                marginRight:"4px"
                 
                }}
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}

        </div>
      );
    } else {
      return (
        <div className="loadingclass">
          {" "}
          <img src={logo} alt="loading..." />
        </div>
      );
    }
  };
  return render();
};

const mapStateToProps = (state) => (

  {
    students: _.get(state, `app.${global.redux.actionTypes.students.name}`, {}),
  }
);

const mapDispatchToProps = (dispatch) => ({
  view: (details) =>
    dispatch(global.redux.action.students.viewprofile(details)),
  studentclass: (details) =>
    dispatch(global.redux.action.students.classdetails(details)),
  classcount: (details) =>
    dispatch(global.redux.action.students.viewclasscount(details)),
  enrolledcourselist: (details) =>
    dispatch(global.redux.action.students.enrolledcourselist(details)),
  classRearrangement: (details) =>
    dispatch(global.redux.action.students.classRearrangement(details)),
  syncSalesForce: (details) =>
    dispatch(global.redux.action.students.syncSalesForce(details)),
    expiryDate: (details) =>
    dispatch(global.redux.action.students.expiryDate(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudentViewPage);