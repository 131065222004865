import React, { useState, useEffect } from 'react';
import { Tabs, Tag } from 'antd';
import { useSearchParams, useLocation } from "react-router-dom";
import * as SecureLS from "secure-ls";
import Profile from '../profile/profile';

import appEnvirontVariables from "../../../core_units/config/env-variables";
import axios from 'axios'
import PreviousClass from '../classmark/previousClass';
import TeacherNoshow from '../classmark/teacherNoshow';
import TeacherWorking from '../classmark/teacherWorking';
import TeacherLeaveDetail from '../classmark/teacherLeaveDetail';
import TeacherLeaveList from '../TeacherList/TeacherLeaveList';
import Calendar from '../slot-calendar/calendar';
import AeCalendar from '../slot-calendar/aeCalendar';
import { Link, useNavigate} from "react-router-dom";
import proimg from '../../../assets/img/default-image.png';
import logo from '../../../assets/gif/aa_loader.gif';
import { connect } from "react-redux";
import _ from 'lodash';
import './profile.css';
import {
    Form,
    Input,
    Button,
    Upload,
    message,
    InputNumber,
    Select,
    notification,
    DatePicker,
    Modal,
    Radio,
  } from "antd";
import StudentListNew from '../slot-calendar/studentlist'
import StudentListAe from '../slot-calendar/studentlistae';

// import StudentList from '../slot-calendar/student-list';
import { data } from 'jquery';
import { Switch } from 'antd';

import { constant } from '../../../core_units/constant';

let ls = new SecureLS({ encodingType: 'aes' });
const token = ls.get('isLogin')
const loginDetails = ls.get('isLogin');
const { TabPane } = Tabs;
const mixpanel = require('mixpanel-browser');

const TeacherViewPage = (props) => {
   console.log(props,"props===99")
    const location = useLocation();
    let history = useNavigate();

    const [teacherId] = useSearchParams();
    const [profile, setProfile] = useState();
    const [classcount, setclasscount] = useState();
    const [studentNumber, setStudentNumber] = useState(null);
    const [studentsList, setStudentsList] = useState(null);

    const [activeList, setActiveList] = useState();
    const[activeItem,setActiveItem] = useState();
   const [teacherDetails,setTeacherDetails] = useState();
   console.log(teacherDetails,"teacherDetails")
   const [isToggleOn, setIsToggleOn] = useState(profile?.enableForPaid || false);
   console.log(isToggleOn,"isToggleOn")
    const [tabkey, settabkey] = useState('1');
    const [scheduleevent, setscheduleevent] = useState()
    const [renderCalender, setRebderCalender] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);
    const [selectedReasonTag, setSelectedReasonTag] = useState(null);
    const [courseList, setCourseList] = useState();
    useEffect(() => {
        setRebderCalender(true);
        apicall(()=>{
           
           // activeClassCount();
        });
        classcountcall();
        configjson();
    }, []);
useEffect(() => {
    currentTeacher();
},[]);
    useEffect(() => {
       // tabfunc();
        callback(teacherId.get('tab'))
    }, [teacherId.get('tab')]);
    const configjson = () => {
        props.courseList({
          callback: (res, data) => {
            if (_.isEqual(res, true)) {
              console.log("courselist", data);
              setCourseList(data);
            }
          },
        });  
      };
    useEffect(() => {
        setIsToggleOn(profile?.enableForPaid || false);
    }, [profile?.enableForPaid]);
  
    const apicall = (cb) => {
        props.view({
            params: {
                teacherId: teacherId.get("credid"),
            },
            callback: (response, data) => {
                if (_.isEqual(response, true)) {
                    setProfile(data);
                    
                }
                if(cb){
                  cb()
                }
            },
        });

    };
    const currentTeacher =() => {
        JSON.parse(localStorage.currentTeacherDetails)
        setTeacherDetails(JSON.parse(localStorage.currentTeacherDetails));
       
    }
    const classcountcall = () => {
        props.classcount({
            params: {
                teacherId: teacherId.get("id"),
            },
            callback: (response, data) => {
                if (_.isEqual(response, true)) {
                    setclasscount(data);
                    
                }
            },
        });
    }
    const activeClassCount = () => {
        
        props.list({
            params: {
                teacherId: teacherId.get("id"),
            },
            callback: (response,data) => {
                
                if(data?.content && data?.content?.length){
                setActiveList(data.content)
                
               
                   
                    const activeItemTemp = data?.content?.find((item) => parseInt(item.teacherId)== parseInt(teacherId.get("id")))
                    setActiveItem(activeItemTemp)
                   
                }
                
                
            },
        });
    }
    
    
    useEffect(() => {
        setProfile(_.get(props, 'teachers.viewteacherprofile'))
    }, [_.get(props, 'teachers.viewteacherprofile.data')])
    const callback = (key) => {
        settabkey(key);
        // hist ory(`/teacher-view?id=${teacherId.get("id")}&&credid=${teacherId.get("credid")}`)
    }
    const onscheduledata = (event) => {
        setscheduleevent(event);
    }

    const fetchStudentsList = async ( teacherId) => {
  
        const url = `${appEnvirontVariables.API.BASE_URL}/api/admin/new/teacher/students?teacherId=${teacherId}`;
      
        try {
          const response = await axios.get(url, {
            headers: { Authorization: token["authorization"] },
          });
          console.log("API Response:", response.data); // Log API response
          setStudentsList(response.data);
          console.log("setnumber", studentsList) // Update state
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };
      

    // const fetchStudentsCount = async ( teacherId) => {
  
    //     const url = `${appEnvirontVariables.API.BASE_URL}/api/studentcourse/teacher/students/count?teacherId=${teacherId}`;
      
    //     try {
    //       const response = await axios.get(url, {
    //         headers: { Authorization: token["authorization"] },
    //       });
    //       console.log("API Response:", response.data); // Log API response
    //       setStudentNumber(response.data);
    //       console.log("setnumber", studentNumber) // Update state
    //     } catch (err) {
    //       console.error("Error fetching data:", err);
    //     }
    //   };
      
    

    const handleToggle = (checked) => {
        setIsToggleOn(checked);
        setIsModalVisible(true); 
      };
      const handleApiCall = () => {
        
        const actionParam = isToggleOn ? "true" : "false";
       if(selectedReason){
        props.enablePaidClass({
            params: {
              teacherId: teacherId.get("id"),
              action: actionParam,
             
            },
            callback: (response, data) => {
              mixpanel.track(constant. MIXPANEL_ENABLE_FOR_PAID,{user:loginDetails?.studentName, ...teacherDetails , enabled:isToggleOn ,reason: selectedReason});
              if (response) {
                setIsModalVisible(false);
                setSelectedReason("");
              }
            },
          });
       }
        
      };
      const showReasonModal = () => {
        setIsModalVisible(true);
      };
      const handleCancel = () => {
        setIsToggleOn(!isToggleOn);
        setIsModalVisible(false);
        setSelectedReason("");
      };

      const activeKey = !_.isEmpty(tabkey)
      ? tabkey
      : teacherId.get("tab")
      ? teacherId.get("tab")
      : "1";

    //   useEffect(() => {
    //     const id = teacherId.get("id"); // Retrieve teacherId dynamically
    //     if (activeKey === "4" && id) {
    //         fetchStudentsCount(id);
    //     }
    // }, [activeKey, teacherId]);

    useEffect(() => {
        const id = teacherId.get("id"); // Retrieve teacherId dynamically
        if (activeKey === "4" && id) {
            fetchStudentsList(id);
        }
    }, [activeKey, teacherId]);
    
      const handleReasonChange = (e) => {
        setSelectedReasonTag(e.target.value);
        if(e.target.value.toLowerCase() != "others"){
           setSelectedReason(e.target.value);
        }else{
            setSelectedReason("");
        }
      }
      const otherReasonchange = (e) => {
        setSelectedReason(e.target.value);
      }
      
    const tabfunc = () => {
        return (
 <Tabs
      defaultActiveKey={"1"}
      activeKey={activeKey}
      onChange={callback}
    >               
                <TabPane tab="Profile" key="1" >
                    <Profile teacherDetails={teacherDetails}/>
                </TabPane>
                <TabPane tab="Calendar" key="2" >
                    {renderCalender ? <Calendar onscheduledata={onscheduledata} /> : ""}
                </TabPane>
                {profile?.isAcademicExpert == true &&
                <TabPane tab="AE Calendar" key="3" >
                    
                    <AeCalendar profile={profile}/>
                </TabPane>}
                <TabPane tab="Students" key="4">
  {profile && profile.isAcademicExpert === true ? (
    <StudentListAe
      {...props}
      scheduleevent={scheduleevent}
      profile={profile}
      studentNumber={studentNumber}
      studentsList={studentsList}
    />
  ) : (
    <StudentListNew
      {...props}
      scheduleevent={scheduleevent}
      profile={profile}
      studentNumber={studentNumber}
      studentsList={studentsList}
    />
  )}
</TabPane>


                <TabPane tab="Previous Class not Marked" key="5" >
                    <PreviousClass/>
                </TabPane>
                <TabPane tab="Noshow Details" key="6" >
                    <TeacherNoshow/>
                </TabPane>
                <TabPane tab="Working Hours" key="7" >
                    <TeacherWorking  profile={profile}/>
                </TabPane>
                <TabPane tab="Leave Details" key="8" >
                    <TeacherLeaveDetail  profile={profile}/>
                </TabPane>
                <TabPane tab="QA List" key="9" >
                    <TeacherLeaveList  profile={profile}/>
                </TabPane>
            </Tabs>
        )
    }


    const render = () => {

        if (_.isEqual(_.get(props, 'teachers.viewteacherprofileLoadingInProgress'), false)) {
            return (
                <div className='profile-container'>
                    <div className="card mb-5 mb-xl-10">
                   
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                <div className="me-7 mb-4">
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        <img
                                            src={!_.isEmpty(_.get(profile, 'profilePhoto')) ? _.get(profile, 'profilePhoto') : proimg} alt="Metornic" />
                                        <div
                                            className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px">
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                <Link to="/chat">View Chat</Link>
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <a href="#"
                                                    className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1">{_.startCase(_.get(profile, 'firstName')) + ' ' + _.startCase(_.get(profile, 'lastName'))} | Teacher </a><a
                                                        href="#">
                                                    {/* <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                                                            viewBox="0 0 24 24" className="mh-50px">
                                                            <path
                                                                d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                                                fill="#00A3FF"></path>
                                                            <path className="permanent"
                                                                d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                                                fill="white"></path>
                                                        </svg>
                                                    </span> */}
                                                </a>
                                            </div>
                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                {_.get(profile, 'mobileNumber') &&
                                                    <a href="#"
                                                        className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                        <span
                                                            className="svg-icon svg-icon-4 me-1">
                                                            <svg width="24" height="24" viewBox="0 0 24 24"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                                <path opacity="0.3"
                                                                    d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                                                    fill="black"></path>
                                                                <path
                                                                    d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                                                    fill="black">

                                                                </path>
                                                            </svg>
                                                        </span>{_.get(profile, 'mobileNumber', '-')}</a>}
                                                {_.get(profile, 'emailId') &&
                                                    <a href="#"
                                                        className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"><span
                                                            className="svg-icon svg-icon-4 me-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                                <path opacity="0.3"
                                                                    d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                    fill="black"></path>
                                                                <path
                                                                    d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                    fill="black"></path>
                                                            </svg></span>{_.get(profile, 'emailId')}</a>
                                                }
                                                {_.get(profile, 'currentAddressCountry') && <a href="#"
                                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"><span
                                                        className="svg-icon svg-icon-4 me-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                            <path opacity="0.3"
                                                                d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                                fill="black"></path>
                                                            <path
                                                                d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                                fill="black"></path>
                                                        </svg></span>{_.get(profile, 'currentAddressCountry')}</a>
                                                }

                                            </div>

                                        </div>

                                    </div>
                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">

                                                <div
                                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center"><span
                                                        className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                        {/* <svg width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                                        className="mh-50px">
                                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1"
                                            transform="rotate(-90 11 18)" fill="black"></rect>
                                        <path
                                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                            fill="black"></path>
                                    </svg> */}
                                                    </span>
                                                        <div className="fs-2 fw-bolder">{_.get(classcount, 'completeClass', 0)}</div>
                                                    </div>
                                                    <div className="fw-bold fs-6 text-gray-400">Completed</div>
                                                </div>
                                                <div
                                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center"><span
                                                        className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                        {/* <svg width="24"
                                        height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                            transform="rotate(90 13 6)" fill="black"></rect>
                                        <path
                                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                            fill="black"></path>
                                    </svg> */}
                                                    </span>
                                                        <div className="fs-2 fw-bolder">{_.get(classcount, 'scheduleClass', 0)}</div>
                                                    </div>
                                                    <div className="fw-bold fs-6 text-gray-400">Scheduled</div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                              
                              {props.teachers.viewteacherprofile.isBatchOffline == true &&
                             <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",paddingTop:"2px",borderRadius:"30px",fontWeight:800}}>Group Offline</Tag>


                             }
                              {props.teachers.viewteacherprofile.isBatchOnline == true &&
                             <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",paddingTop:"2px",borderRadius:"30px",fontWeight:800}}>Group Online</Tag>

                             }
                                                 {props.teachers.viewteacherprofile.is1to1Enabled == true &&
                            <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",width:"13%",paddingTop:"2px",paddingLeft:"37px",borderRadius:"30px",fontWeight:800}}>1:1</Tag>

                             }
                            </div>

                                </div>
                               
                                <Tag color="#f50" style={{height: '25px'}}>{_.get(props, 'teachers.viewteacherprofile.workingStatus')}</Tag>
                               

                                {/* <div style={{width:"35%"}}>
                                {props.teachers.viewteacherprofile.is1to1Enabled == true &&
                               <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",paddingTop:"2px",borderRadius:"30px",width:"29%",paddingLeft:"37px",fontWeight:800}}>1:1</Tag>


                                }
                                 {props.teachers.viewteacherprofile.isBatchOffline == true &&
                                <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",paddingTop:"2px",borderRadius:"30px",width:"29%",fontWeight:800}}>Group Offline</Tag>

                                }
                                 {props.teachers.viewteacherprofile.isBatchOnline == true &&
                                <Tag color="" style={{height: '25px',color:"white",backgroundColor:"#1890ff",paddingTop:"2px",borderRadius:"30px",width:"29%",fontWeight:800}}>Group Online</Tag>

                                }
                               </div> */}
                                
                            </div>
                            <div style={{float:"right"}} className="mb-2"><label style={{fontWeight:"600"}}>Enable for Paid</label>
                          
                          
                            <Switch
                    key={profile?.enableForPaid} 
                    style={{ marginLeft: "10px" }}
                    mask={true}
                    maskClosable={false}
                    checked={isToggleOn}
                    onChange={handleToggle} 
                    onClick={showReasonModal}
                />
                   
                
                            </div>
                            {tabfunc()}
                           
                        </div>
                    </div>
                    <Modal
  title="Select a Reason"
  visible={isModalVisible}
  onOk={handleApiCall}
  onCancel={handleCancel}
>
{isToggleOn ? (
  <Radio.Group onChange={handleReasonChange} value={selectedReasonTag}>
    {courseList?.TeacherToggleButtonEnableReason.map((reason, index) => (
      <Radio key={index} value={reason}>
        {reason}
      </Radio>
    ))}
  </Radio.Group>
) : (
  <Radio.Group onChange={handleReasonChange} value={selectedReasonTag}>
    {courseList?.TeacherToggleButtonDisableReason.map((reason, index) => (
      <Radio key={index} value={reason}>
        {reason}
      </Radio>
    ))}
  </Radio.Group>
)}
{selectedReasonTag == "Others" ? <input className='form-control mt-2' type='input'value={selectedReason} onChange={otherReasonchange} placeholder='Please enter other reason'/> : ""}

</Modal>

                </div>

            )
        } else {
            return <div className='loadingclass'> <img src={logo} alt="loading..." /></div>
        }
    }
    return render();
}
const mapStateToProps = state => (
    {
        teachers: _.get(state, `app.${global.redux.actionTypes.teachers.name}`, {}),

    });

const mapDispatchToProps = dispatch => ({

    view: (details) =>
        dispatch(global.redux.action.teachers.viewteacherprofile(details)),
        list: details => dispatch(global.redux.action.teachers.list(details)),
    classcount: (details) =>
        dispatch(global.redux.action.teachers.viewclasscount(details)),
        enablePaidClass:(details) =>
        dispatch(global.redux.action.teachers.enablePaidClass(details)),
        courseList: (details) =>
        dispatch(global.redux.action.students.courseList(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherViewPage)